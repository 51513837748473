import _ from "lodash"

const findProduct = (arr, row) => {
  return arr.find(p => p.idArticulo === row.A.toString().trim());
}

const createProduct = (data) => {
  return {
    affectedRows: "",
    idArticulo: data.A.toString().trim(),
    vendedores: []
  };
};

const fillChannels = (product, data, channels) => {
  channels.forEach(ch => {
    const meses = [];
    ch.cols.forEach(col => {
      meses.push(data[col]);
    });
    product.vendedores.push({ idVendedor: ch.idVendedor, meses });
  });
}

const fillVendedores = (data, anioFiscal) => {
  var items = Object.keys(data).map(key => ({
    cell: key,
    value: data[key]
  }));

  const groups = _.groupBy(items,  'value');
  
  var headers = Object.keys(groups).map(key => ({
    idVendedor: key,
    cols: groups[key].map(item => item.cell)
  }));

  const result = headers.filter((header) => header.idVendedor !== anioFiscal);
  return result;
}

const fillProduct = (product, data, vendedores) => { 
  product.affectedRows += (product.affectedRows.trim() === '' ? '' : ', ') + data.__rowNum__;
  fillChannels(product, data, vendedores);
};

export const squash = (json, header1 = [], anioFiscal = "") => {
  var result = [];
  const vendedores = fillVendedores(header1, anioFiscal);

  json.forEach(row => {
    if (!row.hasOwnProperty('A') || row.A.toString().toLowerCase().trim() !== "codigo") {
      var product = findProduct(result, row);
      if (product) {
        fillProduct(product, row, vendedores);
      }
      else {
        var newProduct = createProduct(row);
        fillProduct(newProduct, row, vendedores);
        result.push(newProduct);
      }
    }
  });

  return result;
}