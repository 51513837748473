import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  loadAlertas: {
    alertas: [],
    loading: false,
    error: null
  },
  loadInformes: {
    informes: [],
    total: 0,
    loading: false,
    estaCargado: false,
    error: null
  },
  filtrosInformes: {
    idEmpresa: 0,
    fechaDesde: null,
    fechaHasta: null,
    leido: null,
    archivado: null,
    favorito: null,
  }
}

//#region loadAlertas
const loadAlertasStart = (state) => {
  return updateObject(state, {
    loadAlertas: updateObject(state.loadAlertas, {
      loading: true
    })
  });
};

const loadAlertasSuccess = (state, alertas) => {
  return updateObject(state, {
    loadAlertas: updateObject(state.loadAlertas, {
      alertas,
      loading: false
    })
  });
};

const loadAlertasFail = (state, error) => {
  return updateObject(state, {
    loadAlertas: updateObject(state.loadAlertas, {
      loading: false,
      error
    })
  });
};
//#endregion

//#region LOAD INFORMES DEL USUARIO
const loadInformesStart = (state) => {
  return updateObject(state, {
    loadInformes: updateObject(state.loadInformes, {
      loading: true,
    })
  });
};

const loadInformesSuccess = (state, informes) => {
  return updateObject(state, {
    loadInformes: updateObject(state.loadInformes, {
      informes: informes.informes,
      total: informes.cantidadDeInformes,
      loading: false,
      estaCargado: true,
    })
  });
};

const loadInformesFail = (state, error) => {
  return updateObject(state, {
    loadInformes: updateObject(state.loadInformes, {
      loading: false,
      error
    })
  });
};
//#endregion

const updateFiltrosInformes = (state, filtros) => {
  return updateObject(state, {
    filtrosInformes: filtros
  });

}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //#region LOAD ALERTAS DEL USUARIO
    case actionTypes.LOAD_ALERTAS_DEL_USUARIO_START:
      return loadAlertasStart(state);
    case actionTypes.LOAD_ALERTAS_DEL_USUARIO_SUCCESS:
      return loadAlertasSuccess(state, action.alertas);
    case actionTypes.LOAD_ALERTAS_DEL_USUARIO_FAIL:
      return loadAlertasFail(state, action.error);
    //#endregion

    //#region LOAD INFORMES DEL USUARIO
    case actionTypes.LOAD_INFORMES_DEL_USUARIO_START:
      return loadInformesStart(state);
    case actionTypes.LOAD_INFORMES_DEL_USUARIO_SUCCESS:
      return loadInformesSuccess(state, action.informes);
    case actionTypes.LOAD_INFORMES_DEL_USUARIO_FAIL:
      return loadInformesFail(state, action.error);
    //#endregion

    case actionTypes.UPDATE_FILTROS_INFORMES_DEL_USUARIO:
      return updateFiltrosInformes(state, action.filtros);

    default:
      return state;
  }
};

export default reducer;