import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/';
import classes from './Informes.css';
import { Grid, Typography } from "@material-ui/core";
import Spinner from '../../UI/Spinner/Spinner';
import FiltroFechaSelector from "../../UI/FiltroFechaSelector/FiltroFechaSelector";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import ButtonPivotGrid from "../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid";
import { PlayCircleFilledWhiteOutlined } from "@material-ui/icons";
import { storageUsuarioId } from "../../../shared/sessionData";

const InformesFiltros = (props) => {
  const { loading, filtrosInformes, loadInformes, updateFiltrosInformes, empresas } = props;

  var currentDate = new Date();
  const [empresaId, setEmpresaId] = useState();
  const [desde, setDesde] = useState(filtrosInformes && filtrosInformes.fechaDesde 
    ? new Date(filtrosInformes.fechaDesde)
      : new Date(currentDate.setDate(currentDate.getDate() - 7)));
  const [minDate, setMinDate] = useState(null);
  const [loadingDesde, setLoadingDesde] = useState(false);
  const [hasta, setHasta] = useState(filtrosInformes && filtrosInformes.fechaHasta 
    ? new Date(filtrosInformes.fechaHasta)
      : new Date());
  const [maxDate, setMaxDate] = useState(null);
  const [loadingHasta, setLoadingHasta] = useState(false);
  const [cambiosAplicados, setCambiosAplicados] = useState(true);
  const [leido, setLeido] = useState();
  const [archivado, setArchivado] = useState();
  const [favorito, setFavorito] = useState();

  console.log(filtrosInformes);

  const onEmpresaChange = (value) => {
    if (empresaId === value) {
      setEmpresaId(undefined);
    } else {
      setEmpresaId(value);
    }
  }

  const onDesdeChange = (value) => {
    setDesde(value)
    setCambiosAplicados(false);
  };

  const onHastaChange = (value) => {
    setHasta(value);
    setCambiosAplicados(false);
  };

  const onLeidoChange = (event, newValue) => {
    setLeido(newValue);
  };

  const onArchivadosChange = (event, newValue) => {
    setArchivado(newValue);
  };

  const onFavoritosChange = (event, newValue) => {
    setFavorito(newValue);
  };

  const habilitar = () => {
    return false;
  }

  const onAplicarFiltroClick = () => {
    const request = {
      idUsuario: storageUsuarioId(),
      idEmpresa: empresaId,
      leido: leido === null || leido === undefined ? null : leido === 'si',
      favorito: favorito === null || favorito === undefined ? null : favorito === 'si',
      archivado: archivado === null || archivado === undefined ? null : archivado === 'si',
      fechaDesde: desde ? new Date(desde).toUTCString() : null,
      fechaHasta: hasta ? new Date(hasta).toUTCString() : null,
    };
    updateFiltrosInformes(request);
    loadInformes(request);
    setCambiosAplicados(true);
  }

  useEffect(() => {
    const request = {
      idUsuario: storageUsuarioId(),
      fechaDesde: desde ? new Date(desde).toUTCString() : null,
      fechaHasta: hasta ? new Date(hasta).toUTCString() : null,
    };
    loadInformes(request);
    updateFiltrosInformes(request);
  }, []);

  return (
    <Fragment>
      <Grid item xs={12} lg={1}>
        <Typography>
          Filtros por:
        </Typography>
      </Grid>
      <Grid item xs={12} lg={11} container spacing={1} className={classes.containerFlexColumn} style={{ flexWrap: 'nowrap' }}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', zIndex: 1200 }}>
            <div>
              {empresas.map((empresa, key) => (
                !empresa.esGrupoEmpresas && (<button
                  key={key}
                  style={{
                    margin: '0px',
                    outline: 'none',
                    color: empresa.id === empresaId ? '#FFFFFF' : '#CECECE',
                    border: '0.5px solid #CECECE',
                    textAlign: 'center',
                    font: 'normal normal medium 12px/16px Roboto',
                    backgroundColor: empresa.id === empresaId ? '#0CC5FF' : '#FFFFFF',
                    borderRadius: '0px',
                    cursor: 'pointer'
                  }}
                  onClick={() => onEmpresaChange(empresa.id)}
                >
                  {empresa.nombre}
                </button>)
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} container spacing={1} className={classes.containerFlexRow}>
          <Grid item xs={12} md={6} lg={5} container spacing={1} className={classes.containerFlexRow}>
            <Grid item xs={6} lg={5}>
              {loadingDesde ? (
                <Spinner size="small" />
              ) : (
                <FiltroFechaSelector
                  label="Desde"
                  disabled={loading}
                  value={desde}
                  onDateChange={(value) => onDesdeChange(value)}
                  minDate={minDate}
                  maxDate={hasta ? hasta : maxDate}
                  format={'DD/MM/yyyy'}
                />
              )}
            </Grid>
            <Grid item xs={6} lg={5}>
              {loadingHasta ? (
                <Spinner size="small" />
              ) : (
                <FiltroFechaSelector
                  label="Hasta"
                  disabled={loading}
                  value={hasta}
                  onDateChange={(value) => onHastaChange(value)}
                  minDate={desde ? desde : minDate}
                  maxDate={maxDate}
                  format={'DD/MM/yyyy'}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1} className={classes.containerFlexRow}>
            <Grid item xs={3} className={classes.buttonGroupContainer}>
              <Typography className={classes.buttonGroupTitle}>
                Leido / No leido
              </Typography>
              <ToggleButtonGroup
                value={leido}
                onChange={onLeidoChange}
                aria-label="Leido / No leido"
                size="small"
                exclusive={true}
              >
                <ToggleButton value="si" aria-label="si" title="Incluir leídos">
                  <DraftsOutlinedIcon size="small" />
                </ToggleButton>
                <ToggleButton value="no" aria-label="no" title="Incluir no leídos">
                  <EmailOutlinedIcon size="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3} className={classes.buttonGroupContainer}>
              <Typography className={classes.buttonGroupTitle}>
                Archivados
              </Typography>
              <ToggleButtonGroup
                value={archivado}
                onChange={onArchivadosChange}
                aria-label="Archivados"
                size="small"
                exclusive={true}
              >
                <ToggleButton value="si" aria-label="si" title="Incluir archivados">
                  <ArchiveIcon size="small" />
                </ToggleButton>
                <ToggleButton value="no" aria-label="no" title="Incluir no archivados">
                  <ArchiveOutlinedIcon size="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3} className={classes.buttonGroupContainer}>
              <Typography className={classes.buttonGroupTitle}>
                Favoritos
              </Typography>
              <ToggleButtonGroup
                value={favorito}
                onChange={onFavoritosChange}
                aria-label="Favoritos"
                size="small"
                exclusive={true}
              >
                <ToggleButton value="si" aria-label="si" title="Incluir favoritos">
                  <FavoriteIcon size="small" />
                </ToggleButton>
                <ToggleButton value="no" aria-label="no" title="Incluir no favoritos">
                  <FavoriteBorderIcon size="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <ButtonPivotGrid
                tooltipTitle="Aplicar filtros sobre el listado de informes"
                buttonSize="small"
                text="Aplicar"
                onClick={onAplicarFiltroClick}
                loading={loading}
                icon={<PlayCircleFilledWhiteOutlined fontSize="small" />}
                disabled={habilitar()}
                backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.notificaciones.loadInformes.loading,
    filtrosInformes: state.notificaciones.filtrosInformes,
    empresas: state.multiempresa.empresas.empresas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadInformes: (request) => dispatch(actions.loadInformesDelUsuario(request)),
    updateFiltrosInformes: (filtros) => dispatch(actions.updateFiltrosInformes(filtros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformesFiltros);