import React, { Fragment, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import SelectorAnioFiscal from './SelectorAnioFiscalTres';
import SelectorArchivo from './SelectorArchivoTres';
import classes from './ImportadorBudgetForecastTres.css';
import { Tooltip, IconButton } from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import IconConvertToExcel from '../../../../../assets/images/IconConvertToExcel.png';
import ImportadorBudgetForecastTresPDF from '../../../../../assets/documents/Importador3BudgetForecast.pdf';
import TemplateUnidadesBudgetForecastXLSX from '../../../../../assets/documents/TemplateUnidadesBudgetForecast.xlsx';
import TemplatePorcentajesBudgetForecastXLSX from '../../../../../assets/documents/TemplatePorcentajesBudgetForecast.xlsx';
import TemplateInflacionBudgetForecastXLSX from '../../../../../assets/documents/TemplateInflacionBudgetForecast.xlsx';
import TemplateVendedoresBudgetForecastXLSX from '../../../../../assets/documents/TemplateVendedoresBudgetForecast.xlsx';

const ImportadorBudgetForecastTres = (props) => {
  const [anioFiscal, setAnioFiscal] = useState('');
  const [isSelectorArchivoVisible, setIsSelectorArchivoVisible] = useState(false);

  const onAnioFiscalChanged = (date) => {
    if (date !== null) {
      setAnioFiscal(date.id);
      setIsSelectorArchivoVisible(true);
    }
    else {
      setAnioFiscal('');
      setIsSelectorArchivoVisible(false);
    }
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const title = () => {
    return (
      <Typography variant="h5">
        IMPORTADOR BUDGET FORECAST - INFORME 3

        <Tooltip title="Plantilla Excel Unidades">
          <IconButton size="medium" style={{ marginLeft: 20 }} onClick={() => openInNewTab(TemplateUnidadesBudgetForecastXLSX)}>
            <img alt="" src={IconConvertToExcel} height={'43px'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Plantilla Excel % Distribución">
          <IconButton size="medium" style={{ marginLeft: 20 }} onClick={() => openInNewTab(TemplatePorcentajesBudgetForecastXLSX)}>
            <img alt="" src={IconConvertToExcel} height={'43px'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Plantilla Excel Inflación">
          <IconButton size="medium" style={{ marginLeft: 20 }} onClick={() => openInNewTab(TemplateInflacionBudgetForecastXLSX)}>
            <img alt="" src={IconConvertToExcel} height={'43px'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Plantilla Excel Vendedores">
          <IconButton size="medium" style={{ marginLeft: 20 }} onClick={() => openInNewTab(TemplateVendedoresBudgetForecastXLSX)}>
            <img alt="" src={IconConvertToExcel} height={'43px'} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Manual de usuario">
          <IconButton size="medium" style={{ marginLeft: 20 }} onClick={() => openInNewTab(ImportadorBudgetForecastTresPDF)}>
            <BookIcon style={{ color: '#696969' }} fontSize="large" />
          </IconButton>
        </Tooltip>
      </Typography>
    )
  }

  const subTitle = () => {
    return (
      <Fragment>
        <Grid container spacing={1}
          direction="column" justifyContent="flex-start"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} md={11} lg={10} container>
            <Grid item xs={12}>
              <SelectorAnioFiscal anioFiscalChangedCallback={onAnioFiscalChanged} />
            </Grid>
            {isSelectorArchivoVisible && (
              <Grid item xs={12}>
                <hr />
                <SelectorArchivo anioFiscal={`${anioFiscal}`} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container
      style={{
        height: "100%", width: "95vw", minHeight: 500,
        border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
        backgroundColor: "white", borderRadius: "3px"
      }}
    >
      <Grid item xs={12}
        container spacing={1}
        direction='row' justifyContent='space-between' alignContent='flex-start'
      >
        <Grid item xs={9} md={10}
          container className={classes.TitleContainer}
          style={{ margin: 'auto', marginLeft: '20px' }}>
          <Grid item xs={12} className={classes.Title}>
            {title()}
          </Grid>
          <Grid item xs={12} className={classes.Subtitle}>
            {subTitle()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ImportadorBudgetForecastTres;