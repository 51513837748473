import React from "react";
import { Grid } from '@material-ui/core';
import ImportadorBudgetForecastTres from "../../../components/Indicadores/Personalizados/Tenacta/ImportadorTres/ImportadorBudgetForecastTres";

const ImportacionTres = (props) => {
  const { ejecucionDePagina } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ImportadorBudgetForecastTres ejecucionDePagina={ejecucionDePagina} />
      </Grid>
    </Grid>
  )
}

export default ImportacionTres;