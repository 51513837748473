// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Informes__panelContainer__SePmN {\r\n  display: flex;\r\n  justify-content: center;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  padding: 10px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.Informes__containerFlexRow__T1hTs {\r\n  display: flex !important;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelContainer": "Informes__panelContainer__SePmN",
	"containerFlexRow": "Informes__containerFlexRow__T1hTs"
};
export default ___CSS_LOADER_EXPORT___;
