// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportadorBudgetForecastTres__TitleContainer__KvHIn {\r\n  margin-block: 15px !important;\r\n  width: 100%;\r\n  text-align: left !important;\r\n}\r\n\r\n.ImportadorBudgetForecastTres__Title__HEHM6 {\r\n  font-weight: bold !important;\r\n  font-size: medium !important;\r\n}\r\n\r\n.ImportadorBudgetForecastTres__Subtitle__KlWgA {\r\n  font-size: small !important;\r\n}\r\n\r\n.ImportadorBudgetForecastTres__ConfigIcon__innBX {\r\n  margin: 15px !important;\r\n  text-align: end !important;\r\n}\r\n\r\n.ImportadorBudgetForecastTres__TabsContainer__OLyiQ {\r\n  background: #ffffff 0% 0% no-repeat padding-box;\r\n  border-radius: 3px;\r\n  justify-content: center;\r\n}\r\n\r\n.ImportadorBudgetForecastTres__TabsScroller__KcWuR {\r\n  display: flex !important;\r\n  justify-content: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.ImportadorBudgetForecastTres__TabsIndicator__h9Q1h {\r\n  height: 3px !important;\r\n  border-top-right-radius: 5px;\r\n  border-top-left-radius: 5px;\r\n  background-color: #09d4ff !important;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleContainer": "ImportadorBudgetForecastTres__TitleContainer__KvHIn",
	"Title": "ImportadorBudgetForecastTres__Title__HEHM6",
	"Subtitle": "ImportadorBudgetForecastTres__Subtitle__KlWgA",
	"ConfigIcon": "ImportadorBudgetForecastTres__ConfigIcon__innBX",
	"TabsContainer": "ImportadorBudgetForecastTres__TabsContainer__OLyiQ",
	"TabsScroller": "ImportadorBudgetForecastTres__TabsScroller__KcWuR",
	"TabsIndicator": "ImportadorBudgetForecastTres__TabsIndicator__h9Q1h"
};
export default ___CSS_LOADER_EXPORT___;
