// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n\r\n.Informes__containerFlexRow__Ph\\+lj {\r\n    display: flex !important;\r\n    flex-direction: row;\r\n    justify-content: start;\r\n    align-content: center;\r\n  }\r\n  \r\n  .Informes__containerFlexColumn__Q-Ape {\r\n    display: flex !important;\r\n    flex-direction: column;\r\n    align-content: flex-start;\r\n    flex-wrap: nowrap !important;\r\n  }\r\n  \r\n  .Informes__buttonGroupContainer__1Iefj {\r\n    text-align: center;\r\n  }\r\n  \r\n  .Informes__buttonGroupTitle__QnFB4 {\r\n    margin-bottom: 1px;\r\n    font-size: small !important;\r\n  }\r\n  \r\n  .Informes__listadoInformesContainer__qftCz {\r\n    margin-top: 20px !important;\r\n    max-height: 500px;\r\n    overflow-y: scroll;\r\n  }\r\n  \r\n  .Informes__listadoInformesCard__Mh4vO {\r\n    border: 0.5px solid #00000029;\r\n    background-color: white;\r\n    border-radius: 3px;\r\n    margin-top: 2px !important;\r\n    margin-bottom: 2px !important;\r\n    padding-inline: 10px !important;\r\n  }", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFlexRow": "Informes__containerFlexRow__Ph+lj",
	"containerFlexColumn": "Informes__containerFlexColumn__Q-Ape",
	"buttonGroupContainer": "Informes__buttonGroupContainer__1Iefj",
	"buttonGroupTitle": "Informes__buttonGroupTitle__QnFB4",
	"listadoInformesContainer": "Informes__listadoInformesContainer__qftCz",
	"listadoInformesCard": "Informes__listadoInformesCard__Mh4vO"
};
export default ___CSS_LOADER_EXPORT___;
