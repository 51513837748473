import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { storageEmpresaId } from '../../../../../shared/sessionData';
import * as actions from '../../../../../store/actions';
import { Grid } from "@material-ui/core";
import { useFilePicker } from 'use-file-picker';
// import Button from '../../../../UI/Button/Button';
import Spinner from '../../../../UI/Spinner/Spinner';
import Table from '../../../../UI/Table/Table';
import xlsx from 'xlsx'
import * as FileUnidadesValidatorService from "../../../../../services/tenacta/InformeTres/Unidades/FileUnidadesValidatorService";
import * as FileUnidadesFormatterService from "../../../../../services/tenacta/InformeTres/Unidades/FileUnidadesFormatterService";
import * as FilePorcentajesValidatorService from "../../../../../services/tenacta/InformeTres/Porcentajes/FilePorcentajesValidatorService";
import * as FilePorcentajesFormatterService from "../../../../../services/tenacta/InformeTres/Porcentajes/FilePorcentajesFormatterService";
import * as FileInflacionValidatorService from "../../../../../services/tenacta/InformeTres/Inflacion/FileInflacionValidatorService";
import * as FileInflacionFormatterService from "../../../../../services/tenacta/InformeTres/Inflacion/FileInflacionFormatterService";
import * as FileVendedoresValidatorService from "../../../../../services/tenacta/InformeTres/Vendedores/FileVendedoresValidatorService";
import * as FileVendedoresFormatterService from "../../../../../services/tenacta/InformeTres/Vendedores/FileVendedoresFormatterService";
import ConfirmDialog from '../../../../UI/Dialog/ConfirmDialog';
import OKDialog from '../../../../UI/Dialog/OKDialog';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { IconButton, Tooltip, Button } from '@material-ui/core';

const SelectorArchivoTres = (props) => {
  const { onUploadFileDataToServer } = props;
  const { loadingBudgets, budgetsForecasts, onLoadBudgets } = props;
  const { approveLoading } = props;
  const { anioFiscal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorsTableVisible, setIsErrorsTableVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [importDialogText, setImportDialogText] = useState(false);
  const [idMaster, setIdMaster] = useState(0);
  const [tipoArchivo, setTipoArchivo] = useState(null);

  const [openFileUnidadesSelector, {
    filesContent: filesUnidadesContent,
    fileLoading: fileUnidadesLoading,
    clear: clearUnidades }] = useFilePicker({
      accept: ['.xlsx'],
      readAs: "ArrayBuffer"
    });

  const [openFilePorcentajesSelector, {
    filesContent: filesPorcentajesContent,
    fileLoading: filePorcentajesLoading,
    clear: clearPorcentajes }] = useFilePicker({
      accept: ['.xlsx'],
      readAs: "ArrayBuffer"
    });

  const [openFileInflacionSelector, {
    filesContent: filesInflacionContent,
    fileLoading: fileInflacionLoading,
    clear: clearInflacion }] = useFilePicker({
      accept: ['.xlsx'],
      readAs: "ArrayBuffer"
    });

  const [openFileVendedoresSelector, {
    filesContent: filesVendedoresContent,
    fileLoading: fileVendedoresLoading,
    clear: clearVendedores }] = useFilePicker({
      accept: ['.xlsx'],
      readAs: "ArrayBuffer"
    });

  useEffect(() => {
    setValidationErrors([]);
  }, [filesUnidadesContent, filesPorcentajesContent, filesInflacionContent, filesVendedoresContent]);

  // budget-forecast-table columns
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220 220 220)'
  };
  const columns = [
    { title: 'Archivo', field: 'fileName', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Fila', field: 'rowNumber', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Artículo', field: 'product', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Errores', field: 'errors', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle, render: rowData => rowData.errors ? (<ul>{rowData.errors.map(err => <li>{err}</li>)}</ul>) : "" }
  ];

  const excelToJson = (data) => {
    const workbook = xlsx.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = xlsx.utils.sheet_to_json(worksheet, { header: "A", range: 0 });
    return json;
  };

  const handleUploadFilesToS3 = async (fileUnidades, filePorcentajes, fileInflacion, fileVendedores, callback) => {
    await handleUploadFileToS3(fileUnidades.file, fileUnidades.folder, fileUnidades.id, async () => {
      await handleUploadFileToS3(filePorcentajes.file, filePorcentajes.folder, filePorcentajes.id, async () => {
        await handleUploadFileToS3(fileInflacion.file, fileInflacion.folder, fileInflacion.id, async () => {
          await handleUploadFileToS3(fileVendedores.file, fileVendedores.folder, fileVendedores.id, callback);
        });
      });
    });
  }

  const handleUploadFileToS3 = async (file, folder, idArchivo, callback) => {
    if (file == null) {
      callback();
      return;
    }
    const extention = file.name.split('.').pop();
    const fileName = `${folder}/${folder}-${idArchivo.toString().trim()}.${extention}`
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const newArrayBuffer = file.content;
    const newBlob = new Blob([newArrayBuffer], { type: contentType });
    const newFile = new File([newBlob], fileName, { type: contentType });

    var formData = new FormData()
    formData.append("ContainerName", "budgets-forecasts-informe2")
    formData.append("FileName", `${fileName}`)
    formData.append("ContentType", `${contentType}`)
    formData.append("File", newFile)

    props.onUploadFileToStorageAccount(formData)
    callback();
  }

  const onUploadFileDataToServerSuccess = async (data, fileUnidades, filePorcentajes, fileInflacion, fileVendedores) => {
    if (data.hasErrors) {
      const errors = data.errors.map(error => (
        {
          fileName: error.fileName,
          rowNumber: error.affectedRows,
          product: error.idArticulo,
          errors: error.errors
        }
      ));
      setValidationErrors(errors);
      setIsLoading(false);
    }
    else {
      const uploadFileUnidades = {
        id: data.idArchivoUnidades,
        file: fileUnidades,
        folder: "Unidades",
      };

      const uploadFilePorcentajes = {
        id: data.idArchivoPorcentajes,
        file: filePorcentajes,
        folder: "Porcentajes",
      };

      const uploadFileInflacion = {
        id: data.idArchivoInflacion,
        file: fileInflacion,
        folder: "Inflación",
      };

      const uploadFileVendedores = {
        id: data.idArchivoVendedor,
        file: fileVendedores,
        folder: "Vendedores",
      };

      await handleUploadFilesToS3(uploadFileUnidades, uploadFilePorcentajes, uploadFileInflacion, uploadFileVendedores, () => {
        setIsLoading(false);
      });
    }
  }

  const onUploadFileDataToServerFail = (error) => {
    setIsLoading(false);
    alert(error);
  }

  useEffect(() => {
    const isErrorsTableVisible =
      !fileUnidadesLoading
      && !filePorcentajesLoading
      && !fileInflacionLoading
      && !fileVendedoresLoading
      && !isLoading
      && !loadingBudgets
      && validationErrors.length > 0;
    setIsErrorsTableVisible(isErrorsTableVisible);
  }, [fileUnidadesLoading, filePorcentajesLoading, fileInflacionLoading, fileVendedoresLoading, isLoading, validationErrors]);

  useEffect(() => {
    clearUnidades();
    clearPorcentajes();
    clearInflacion();
    clearVendedores();
    setValidationErrors([]);

    var idMaster = 0;
    var tipoArchivo = null;
    var importDialogText = "";
    if (!loadingBudgets) {
      var forecastExists = budgetsForecasts.filter(i => i.tipo === "F").length > 0;
      var notApprovedForecastExists = budgetsForecasts.filter(i => i.tipo === "F" && !i.aprobado).length > 0;
      var budgetExists = budgetsForecasts.filter(i => i.tipo === "B").length > 0;
      var notApprovedBudgetExists = budgetsForecasts.filter(i => i.tipo === "B" && !i.aprobado).length > 0;

      if (forecastExists) {
        tipoArchivo = "F";
        if (notApprovedForecastExists) {
          const forecast = budgetsForecasts.find(i => i.tipo === "F" && !i.aprobado);
          idMaster = forecast.id;
          importDialogText = (
            <Fragment>
              <b>Esta acción actualizará el último Forecast importado.</b><br />
              <br />
              <u>NOTA:</u> En caso de querer importar un nuevo Forecast y mantener el anterior,<br />
              previamente deberá aprobar el Forecast no aprobado.<br />
              <br />
              Para continuar, por favor confirme la acción.
            </Fragment>);
        }
        else {
          importDialogText = (
            <Fragment>
              <b>Esta acción importará un Forecast adicional.</b><br />
              <br />
              Para continuar, por favor confirme la acción.
            </Fragment>);
        }
      }
      else {
        if (budgetExists) {
          if (notApprovedBudgetExists) {
            const budget = budgetsForecasts.find(i => i.tipo === "B" && !i.aprobado);
            idMaster = budget.id;
            tipoArchivo = "B";
            importDialogText = (
              <Fragment>
                <b>Esta acción actualizará el Budget previamente importado.</b><br />
                <br />
                <u>NOTA:</u> En caso de querer importar un Forecast, previamente deberá aprobar el Budget.<br />
                <br />
                Para continuar, por favor confirme la acción.
              </Fragment>);
          }
          else {
            tipoArchivo = "F";
            importDialogText = (
              <Fragment>
                <b>Esta acción importará un Forecast.</b><br />
                <br />
                Para continuar, por favor confirme la acción.
              </Fragment>);
          }
        }
        else {
          tipoArchivo = "B";
          importDialogText = (
            <Fragment>
              <b>Esta acción importará un Budget.</b><br />
              <br />
              Para continuar, por favor confirme la acción.
            </Fragment>);
        }
      }
    }

    setIdMaster(idMaster);
    setTipoArchivo(tipoArchivo);
    setImportDialogText(importDialogText);
  }, [loadingBudgets, budgetsForecasts]);

  useEffect(() => {

    if (!isLoading && !props.loadingExportador && props.estaCargadoExportador) {
      clearUnidades(); // reset file uploaded
      clearPorcentajes(); // reset file uploaded
      clearInflacion(); // reset file uploaded
      clearVendedores(); // reset file uploaded
      onLoadBudgets(anioFiscal);
      handleImportFileOKDialogOpen();
    }

    if (props.errorExportador)
      console.log(props.errorExportador)
  }, [props.loadingExportador])

  const validateAndProcessFile = (filesContent, validator, formatter, headerSize) => {
    if (!filesContent || !filesContent.length) return { result: null, errors: [] };

    const file = filesContent[0];
    // convert excel to json
    const json = excelToJson(file.content);
    // validate budget/forecast uploaded locally
    const errors = validator.validate(json, anioFiscal);

    if (errors && errors.length > 0) return { result: null, file, errors };

    const header1 = json[0];
    const result = formatter.squash(json.slice(headerSize), header1, anioFiscal);
    return { result, file, errors: [] };
  }

  const processAndUploadFiles = () => {
    setImportFileConfirmDialogOpen(false);
    setIsLoading(true);
    setValidationErrors([]);

    const { result: resultUnidades,
      file: fileUnidades,
      errors: errorsUnidades
    } = validateAndProcessFile(filesUnidadesContent, FileUnidadesValidatorService, FileUnidadesFormatterService, 1);

    const { result: resultPorcentajes,
      file: filePorcentajes,
      errors: errorsPorcentajes
    } = validateAndProcessFile(filesPorcentajesContent, FilePorcentajesValidatorService, FilePorcentajesFormatterService, 2);

    const { result: resultInflacion,
      file: fileInflacion,
      errors: errorsInflacion
    } = validateAndProcessFile(filesInflacionContent, FileInflacionValidatorService, FileInflacionFormatterService, 1);

    const { result: resultVendedores,
      file: fileVendedores,
      errors: errorsVendedores
    } = validateAndProcessFile(filesVendedoresContent, FileVendedoresValidatorService, FileVendedoresFormatterService, 1);

    if (errorsUnidades.length || errorsPorcentajes.length || errorsInflacion.length || errorsVendedores.length) {
      setValidationErrors([...errorsUnidades, ...errorsPorcentajes, ...errorsInflacion, ...errorsVendedores]);
      setIsLoading(false);
    }
    else {
      const data = {
        idMaster,
        anioFiscal,
        tipoArchivo,
        unidades: resultUnidades,
        canales: resultPorcentajes,
        inflacion: resultInflacion,
        vendedores: resultVendedores
      }

      // upload data to server
      onUploadFileDataToServer(data, fileUnidades, filePorcentajes, fileInflacion, fileVendedores, onUploadFileDataToServerSuccess, onUploadFileDataToServerFail);
    }
  }

  // Import confirm dialog
  const [importFileConfirmDialogOpen, setImportFileConfirmDialogOpen] = useState(false);

  const handleImportFileConfirmDialogOpen = () => {
    setImportFileConfirmDialogOpen(true);
  };

  const handleImportFileConfirmDialogClose = () => {
    setImportFileConfirmDialogOpen(false);
  };

  const importFileConfirmDialog = () => (
    <ConfirmDialog
      show={importFileConfirmDialogOpen}
      title="Importar Budget/Forecast"
      body={importDialogText}
      onConfirm={() => processAndUploadFiles()}
      onClose={handleImportFileConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - Import confirm dialog

  // Import OK dialog at the end
  const [importFileOKDialogOpen, setImportFileOKDialogOpen] = useState(false);

  const handleImportFileOKDialogOpen = () => {
    setImportFileOKDialogOpen(true);
  };

  const handleImportFileOKDialogClose = () => {
    setImportFileOKDialogOpen(false);
  };

  const importFileOKDialog = () => (
    <OKDialog
      show={importFileOKDialogOpen}
      title="Importar Budget/Forecast"
      body="Archivo importado exitosamente."
      onConfirm={handleImportFileOKDialogClose}
      onClose={handleImportFileOKDialogClose}
    >
    </OKDialog>
  );
  // - Import OK dialog

  const ArchivoButton = (props) => {
    const { onClick, disabled, label, filesContent, clear } = props;

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            fullWidth={true}
            style={{ fontSize: 'small' }}
            startIcon={disabled ? <Spinner size="xs" /> : <CloudUploadIcon fontSize="small" />}>
            {`Cargar ${'\n'}${label}`}
          </Button>
        </Grid>
        {filesContent.length > 0 &&
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title="Limpiar archivo" style={{ maxWidth:'20%' }}>
              <IconButton size="small"
                color="primary"
                onClick={() => {
                  clear();
                  setIsErrorsTableVisible(false);
                  setValidationErrors([]);
                }}>
                {!disabled ? <ClearIcon fontSize="small" /> : <></>}
              </IconButton>
            </Tooltip>
            <h5 style={{ maxWidth:'80%', overflowWrap: 'break-word' }}>
              {filesContent[0].name}
            </h5>
          </Grid>
        }
      </Grid>
    );
  }

  const disableImportButton = useMemo(() => {
    const noneBudgets = (budgetsForecasts && budgetsForecasts.length == 0);
    const allFileSelected = (filesUnidadesContent && filesUnidadesContent.length > 0)
      && (filesPorcentajesContent && filesPorcentajesContent.length > 0)
      && (filesInflacionContent && filesInflacionContent.length > 0)
      && (filesVendedoresContent && filesVendedoresContent.length > 0);
    const noneFileSelected = ((!filesUnidadesContent || filesUnidadesContent.length == 0)
      && (!filesPorcentajesContent || filesPorcentajesContent.length == 0)
      && (!filesVendedoresContent || filesVendedoresContent.length == 0))

    return fileUnidadesLoading
      || filePorcentajesLoading
      || fileInflacionLoading
      || fileVendedoresLoading
      || noneFileSelected
      || (noneBudgets && !allFileSelected)
      || isLoading
      || loadingBudgets
      || approveLoading;

  }, [fileUnidadesLoading, filePorcentajesLoading, fileInflacionLoading, fileVendedoresLoading,
    filesUnidadesContent, filesPorcentajesContent, filesInflacionContent, filesVendedoresContent,
    isLoading, loadingBudgets, budgetsForecasts, approveLoading]);

  return (
    <Grid container spacing={1}
      direction="column" justifyContent="flex-start"
      style={{ marginTop: '10px' }}
    >
      <Grid item xs={12} container>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={2}>
            <ArchivoButton
              onClick={openFileUnidadesSelector}
              disabled={fileUnidadesLoading || isLoading || loadingBudgets || approveLoading}
              label={"Unidades"}
              filesContent={filesUnidadesContent}
              clear={clearUnidades} />
          </Grid>
          <Grid item xs={2}>
            <ArchivoButton
              onClick={openFilePorcentajesSelector}
              disabled={filePorcentajesLoading || isLoading || loadingBudgets || approveLoading}
              label={"% Distrib."}
              filesContent={filesPorcentajesContent}
              clear={clearPorcentajes} />
          </Grid>
          <Grid item xs={2}>
            <ArchivoButton
              onClick={openFileInflacionSelector}
              disabled={fileInflacionLoading || isLoading || loadingBudgets || approveLoading}
              label={"Inflación"}
              filesContent={filesInflacionContent}
              clear={clearInflacion} />
          </Grid>
          <Grid item xs={2}>
            <ArchivoButton
              onClick={openFileVendedoresSelector}
              disabled={fileVendedoresLoading || isLoading || loadingBudgets || approveLoading}
              label={"Vendedores"}
              filesContent={filesVendedoresContent}
              clear={clearVendedores} />
          </Grid>
          <Grid item xs={2}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleImportFileConfirmDialogOpen}
              disabled={disableImportButton}
              startIcon={<CloudUploadIcon fontSize="small" />}>
              {"Procesar Archivos"}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isLoading && <Spinner size="small" />}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {isErrorsTableVisible &&
          <Table
            style={{ width: '100%', marginTop: 0, marginBottom: '15px' }}
            title=""
            noBoxShadow
            columns={columns}
            data={validationErrors}
            customOptions={{
              thirdSortClick: false,
              sorting: false,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 450
            }}
            tableKey={"tabla-selector-archivo"}
          />
        }
      </Grid>
      {importFileConfirmDialog()}
      {importFileOKDialog()}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    uploadingResult: state.tenacta.importadorBudgetForecast.items,
    loadingBudgets: state.tenacta.budgetsForecastsInformeTres.loading,
    budgetsForecasts: state.tenacta.budgetsForecastsInformeTres.items,
    approveLoading: state.tenacta.aprobarBudgetForecast.loading,
    loadingExportador: state.tenacta.exportadorBudgetForecast.loading,
    errorExportador: state.tenacta.exportadorBudgetForecast.error,
    estaCargadoExportador: state.tenacta.exportadorBudgetForecast.estaCargado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadFileDataToServer: (data, fileUnidades, filePorcentajes, fileInflacion, fileVendedores, onSuccess, onFail) =>
      dispatch(actions.uploadInformeTresFileDataToServer(data, fileUnidades, filePorcentajes, fileInflacion, fileVendedores, onSuccess, onFail)),
    onLoadBudgets: (anioFiscal) => dispatch(actions.loadInformeTresBudgetsForecasts(anioFiscal)),
    onUploadFileToStorageAccount: (data) => dispatch(actions.uploadFileToStorageAccount(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorArchivoTres);