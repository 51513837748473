import React from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/';
import classes from './Informes.css';
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Spinner from "../../UI/Spinner/Spinner";
import { DraftsOutlined, EmailOutlined } from "@material-ui/icons";
import moment from "moment";

const InformesListado = (props) => {
  const { loading, informes, filtrosInformes, descargarInforme, marcarInformeComoLeido,
    marcarInformeComoFavorito, archivarInforme, eliminarInforme } = props;

  const descargarArchivoSuccess = (response) => {
    const { base64data, fileName } = response;
    const byteCharacters = atob(base64data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const onDescargarArchivo = (id) => {
    descargarInforme(id, descargarArchivoSuccess);
  }

  return loading ? (
    <Spinner />
  ) : (
    <Grid item xs={12} lg={8} container spacing={1} className={classes.listadoInformesContainer}>
      {informes && informes.map((informe, key) => (
        <Grid key={key} item xs={12} container spacing={1} className={classes.listadoInformesCard} style={{ justifyContent: 'space-between' }}>
          <Grid item xs={6} lg={77}>
            <Typography style={{ fontSize: 'small', fontWeight: informe.leido ? '300' : '600' }}>
              <span>{informe.nombreEmpresa} - {informe.titulo}</span><br />
              <span>{moment(informe.fecha).format('DD/MM/YYYY HH:mm')}</span>
            </Typography>
          </Grid>
          <Grid item xs={6} lg={5} container spacing={1}>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Descargar informe">
                <IconButton onClick={() => onDescargarArchivo(informe.idInforme)} size="small">
                  <GetAppOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              {informe.leido ? (
                <Tooltip title="Marcar como no leido">
                  <IconButton onClick={() => marcarInformeComoLeido(informe.id, filtrosInformes)} size="small">
                    <DraftsOutlined />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Marcar como leido">
                  <IconButton onClick={() => marcarInformeComoLeido(informe.id, filtrosInformes)} size="small">
                    <EmailOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={2}>
              {informe.favorito ? (
                <Tooltip title="Desmarcar como favorito">
                  <IconButton onClick={() => marcarInformeComoFavorito(informe.id, filtrosInformes)} size="small">
                    <FavoriteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Marcar como favorito">
                  <IconButton onClick={() => marcarInformeComoFavorito(informe.id, filtrosInformes)} size="small">
                    <FavoriteBorderIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={2}>
              {informe.archivado ? (
                <Tooltip title="Desarchivar informe">
                  <IconButton onClick={() => archivarInforme(informe.id, filtrosInformes)} size="small">
                    <ArchiveIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Archivar informe">
                  <IconButton onClick={() => archivarInforme(informe.id, filtrosInformes)} size="small">
                    <ArchiveOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Eliminar informe">
                <IconButton onClick={() => eliminarInforme(informe.id, filtrosInformes)} size="small">
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.notificaciones.loadInformes.loading,
    informes: state.notificaciones.loadInformes.informes,
    filtrosInformes: state.notificaciones.filtrosInformes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    descargarInforme: (id, success) => dispatch(actions.descargarInforme(id, success)),
    marcarInformeComoLeido: (id, filtrosInformes) => dispatch(actions.marcarInformeComoLeido(id, filtrosInformes)),
    marcarInformeComoFavorito: (id, filtrosInformes) => dispatch(actions.marcarInformeComoFavorito(id, filtrosInformes)),
    archivarInforme: (id, filtrosInformes) => dispatch(actions.archivarInforme(id, filtrosInformes)),
    eliminarInforme: (id, filtrosInformes) => dispatch(actions.eliminarInforme(id, filtrosInformes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformesListado);