import * as actionTypes from './actionTypes';

export const cambioPaginaRender = (page) => {
  return {
    type: actionTypes.CAMBIO_PAGINA_RENDER,
    page
  };
};

export const ejecutarFotoDelDia = () => {
  return {
    type: actionTypes.EJECUTAR_FOTO_DEL_DIA
  };
};

export const ejecutarGastos = () => {
  return {
    type: actionTypes.EJECUTAR_GASTOS
  };
};

export const ejecutarMultiempresa = () => {
  return {
    type: actionTypes.EJECUTAR_MULTIEMPRESA
  };
};

export const ejecutarResultado = () => {
  return {
    type: actionTypes.EJECUTAR_RESULTADO
  };
};

export const ejecutarAnalisisDeVentas = () => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_DE_VENTAS
  };
};

export const ejecutarAnalisisDeVendedor = () => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_DE_VENDEDOR
  };
};

export const ejecutarAnalisisDeSucursal = () => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_DE_SUCURSAL
  };
};

export const ejecutarAnalisisDeUtilidad = () => {
  return {
    type: actionTypes.EJECUTAR_ANALISIS_DE_UTILIDAD
  };
};

export const ejecutarTiemposReq = () => {
  return {
    type: actionTypes.EJECUTAR_TIEMPOS_REQ
  };
};

export const ejecutarNoganetHome = () => {
  return {
    type: actionTypes.EJECUTAR_NOGANET_HOME
  }
}

export const ejecutarTenactaHome = () => {
  return  {
    type: actionTypes.EJECUTAR_HOME_TENACTA
  }
}

export const ejecutarTenactaInformes = () => {
  return  {
    type: actionTypes.EJECUTAR_INFORMES_TENACTA
  }
}

export const ejecutarTenactaInformeDos = () => {
  return  {
    type: actionTypes.EJECUTAR_INFORME_DOS_TENACTA
  }
}

export const ejecutarTenactaInformeTres = () => {
  return  {
    type: actionTypes.EJECUTAR_INFORME_TRES_TENACTA
  }
}

export const ejecutarInformes = () => {
  return  {
    type: actionTypes.EJECUTAR_INFORMES
  }
}

export const resetEjecucionPaginas = () => {
  return  {
    type: actionTypes.RESET_EJECUCION_PAGINAS
  }
}