const inDev = process.env.NODE_ENV === 'development';
const inTest = true; 

const devPortalUrl = 'http://localhost:3003';
const testPortalUrl = 'https://develop.login.jalisco365.com.ar';
const prodPortalUrl = 'https://login.jalisco365.com.ar';

const devVendedoresUrl = 'http://localhost:3001';
const testVendedoresUrl = 'https://develop.vendedores.jalisco365.com.ar';
const prodVendedoresUrl = 'https://vendedores.jalisco365.com.ar';

const devDashboardUrlBase = 'https://localhost:44392/api/';
const testDashboardUrlBase = 'https://dev.api.alertas.dashboard.jalisco365.com.ar/api/';
const prodDashboardUrlBase = 'https://dashboard.api.jalisco365.com.ar/api/';

const devAlertasUrlBase = 'https://localhost:44334/';
const testAlertasUrlBase = 'https://develop.alarmas.api.jalisco365.com.ar/';
const prodAlertasUrlBase = 'https://alarmas.api.jalisco365.com.ar/';

export const urlPortal = () => {
  return window.location.replace(inDev ? devPortalUrl : inTest ? testPortalUrl : prodPortalUrl);
};

export const urlPortalLogout = () => {
  return window.location.replace(
    inDev ? `${devPortalUrl}/logout` : inTest ? `${testPortalUrl}/logout` : `${prodPortalUrl}/logout`
  );
};

export const urlVendedores = () => {
  return window.location.replace(
    inDev
      ? `${devVendedoresUrl}?${sessionStorage.getItem('token')}`
      : inTest
      ? `${testVendedoresUrl}?${sessionStorage.getItem('token')}`
      : `${prodVendedoresUrl}?${sessionStorage.getItem('token')}`
  );
};

export const baseUrlDashboard = () => {
  return inDev ? devDashboardUrlBase : inTest ? testDashboardUrlBase : prodDashboardUrlBase;
};

export const baseUrlAlertas = () => {
  return inDev ? devAlertasUrlBase : inTest ? testAlertasUrlBase : prodAlertasUrlBase;
}