import React from "react";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid } from "@material-ui/core";
import { storageUsuarioId } from "../../../shared/sessionData";

const InformesPaginado = (props) => {
  const { total, filtrosInformes, loadInformes, updateFiltrosInformes } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onLoadInformes(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const paginaSize = parseInt(event.target.value, 10);
    setRowsPerPage(paginaSize);
    setPage(0);
    onLoadInformes(1, paginaSize);
  };

  const onLoadInformes = (pagina, paginaSize) => {    
    const request = {
      idUsuario: storageUsuarioId(),
      idEmpresa: filtrosInformes.idEmpresa,
      leido: filtrosInformes.leido,
      favorito: filtrosInformes.favorito,
      archivado: filtrosInformes.archivado,
      fechaDesde: filtrosInformes.fechaDesde,
      fechaHasta: filtrosInformes.fechaHasta,
      pagina,
      paginaSize
    };
    updateFiltrosInformes(request);
    loadInformes(request);
  }

  return (
    <Grid item xs={12} lg={8} container spacing={1} justifyContent="flex-end">
      <Grid item xs={12}>
        <TablePagination
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    total: state.notificaciones.loadInformes.total,
    filtrosInformes: state.notificaciones.filtrosInformes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadInformes: (request) => dispatch(actions.loadInformesDelUsuario(request)),
    updateFiltrosInformes: (filtros) => dispatch(actions.updateFiltrosInformes(filtros)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformesPaginado);