import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions';
import { Grid } from "@material-ui/core";
import CustomSelector from "../../../../UI/CustomSelector/CustomSelector";
import Spinner from '../../../../UI/Spinner/Spinner';
import Table from '../../../../UI/Table/Table';
import IconConvertToExcel from '../../../../../assets/images/IconConvertToExcel.png';
import { Tooltip, IconButton } from '@material-ui/core';
import ApproveIcon from '@material-ui/icons/HowToReg';
import ConfirmDialog from '../../../../UI/Dialog/ConfirmDialog';
import OKDialog from '../../../../UI/Dialog/OKDialog';
import FiltroFechaSelector from "../../../../UI/FiltroFechaSelector/FiltroFechaSelector";

const ExcelType = {
  Unidades: { folder: "Unidades", description: "Unidades" },
  Porcentajes: { folder: "Porcentajes", description: "Porcentajes" },
  Inflacion: { folder: "Inflación", description: "Inflación" },
  Vendedores: { folder: "Vendedores", description: "Vendedores" },
}

const SelectorAnioFiscalTres = (props) => {
  const { loading, aniosFiscales, estaCargado, onLoadAniosFiscales } = props;
  const { onLoadBudgets, loadingBudgets, budgetsForecasts } = props;
  const { loadingUploadBudgets } = props;
  const { onApproveFile, approveLoading } = props;
  const { anioFiscalChangedCallback } = props;

  const [anioFiscal, setAnioFiscal] = useState();  
  const [anioFiscalDate, setAnioFiscalDate] = useState();  
  const [anioFiscalDesde, setAnioFiscalDesde] = useState();
  const [anioFiscalHasta, setAnioFiscalHasta] = useState();
  const [data, setData] = useState([]);
  const [idMaster, setIdMaster] = useState(0);

  const openInNewTab = (fileName, folder) => {
    props.onDownloadFile(`${folder}`, `${folder}-${fileName}.xlsx`, `budgets-forecasts-informe2`)

    // window.open(`https://jalisco.s3.amazonaws.com/budgets_forecasts_informe2/${idEmpresa}/${folder}/${folder}-${fileName}.xlsx`, '_blank', 'noopener,noreferrer');
  };

  const descargarExcelRowButton = (fileName, type) => (
    <Tooltip title={`Descargar Excel ${type.description}`}>
      <IconButton size="small" style={{ marginLeft: 20 }} onClick={() => openInNewTab(fileName, type.folder)}>
        <img alt="" src={IconConvertToExcel} height={'33px'} />
      </IconButton>
    </Tooltip>
  );

  const aprobarArchivoRowButton = () => (
    <Tooltip title="Aprobar">
      <IconButton size="small" style={{ marginLeft: 20 }}
        onClick={handleApproveFileConfirmDialogOpen}
        disabled={loadingUploadBudgets || approveLoading}>
        <ApproveIcon height={'33px'} style={{ color: '#10c5ff' }} />
      </IconButton>
    </Tooltip>
  );

  // budget-forecast-table columns
  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '5px',
    paddingTop: '5px'
  };
  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#f99b3a'
  };
  const columns = [
    { title: 'ID', width: "5%", field: 'id', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Archivo', width: "15%", field: 'fileName', align: 'left', cellStyle: cellStyle, headerStyle: headerStyle },
    { title: 'Estado', width: "10%", field: 'status', align: 'center', cellStyle: cellStyle, headerStyle: headerStyle },
    {
      title: 'Acciones',
      width: "15%",
      field: null,
      align: 'center',
      cellStyle: cellStyle,
      headerStyle: headerStyle,
      render: (rowData) => {
        return (
          <Grid item xs={12} container
            direction="row" justifyContent="flex-start">
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={3}>
                  {descargarExcelRowButton(rowData.idArchivoUnidades, ExcelType.Unidades)}
                </Grid>
                <Grid item xs={3}>
                  {descargarExcelRowButton(rowData.idArchivoPorcentajes, ExcelType.Porcentajes)}
                </Grid>
                <Grid item xs={3}>
                  {descargarExcelRowButton(rowData.idArchivoInflacion, ExcelType.Inflacion)}
                </Grid>
                <Grid item xs={3}>
                  {descargarExcelRowButton(rowData.idArchivoVendedor, ExcelType.Vendedores)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {!rowData.approved ? aprobarArchivoRowButton() : (<span></span>)}
            </Grid>
          </Grid>
        );
      }
    }
  ];

  const onLoadBudgetsFail = (error) => {
    if (error.status !== 404)
      alert(error.data);
  }

  const onAnioFiscalChange = (value) => {
    setAnioFiscalDate(value);
    const anio = new Date(value).getFullYear();
    const anioValue = { id: anio, nombre: anio };
    setAnioFiscal(anioValue);
    onLoadBudgets(anioValue.id, onLoadBudgetsFail);
    anioFiscalChangedCallback(anioValue);
  }

  const onApproveFileSuccess = () => {
    // alert('Archivo aprobado');
    handleApproveFileOKDialogOpen();
    onLoadBudgets(anioFiscal.id, onLoadBudgetsFail);
  }

  const onApproveFileFail = (error) => {
    alert(error);
  }

  const onAprobarArchivo = () => {
    handleApproveFileConfirmDialogClose();
    onApproveFile(idMaster, onApproveFileSuccess, onApproveFileFail);
  }

  useEffect(() => {
    if (!anioFiscal || anioFiscal.id === undefined) {
      setData([]);
    }
  }, [anioFiscal]);

  useEffect(() => {
    if (!estaCargado && !loading)
      onLoadAniosFiscales();
  }, [estaCargado, loading]);

  

  useEffect(() => {
    if (!anioFiscalDesde && estaCargado && aniosFiscales && aniosFiscales.length > 0) {
      const anio = Number(aniosFiscales[aniosFiscales.length - 1].id);
      setAnioFiscalDesde(new Date(anio, 0, 1));
    }
  }, [anioFiscalDesde, estaCargado, aniosFiscales]);

  useEffect(() => {
    if (!anioFiscalHasta && estaCargado && aniosFiscales && aniosFiscales.length > 0) {
      const anio = Number(aniosFiscales[0].id);
      setAnioFiscalHasta(new Date(anio, 11, 31));
    }
  }, [anioFiscalHasta, estaCargado, aniosFiscales]);


  useEffect(() => {
    var data = [];
    setIdMaster(0);

    if (loadingBudgets)
      return;

    if (anioFiscal
      && !(Object.keys(anioFiscal).length === 0
        && Object.getPrototypeOf(anioFiscal) === Object.prototype)) {
      data = budgetsForecasts.map(item => ({
        id: item.id,
        idArchivoUnidades: item.idArchivoUnidades,
        idArchivoPorcentajes: item.idArchivoPorcentajes,
        idArchivoInflacion: item.idArchivoInflacion,
        idArchivoVendedor: item.idArchivoVendedor,
        fileName: item.nombre,
        status: item.aprobado ? "Aprobado" : "No aprobado",
        approved: item.aprobado
      }));
      data = data.sort((a, b) => {
        if (parseInt(a.id) >= parseInt(b.id))
          return 1;
        return -1;
      });
      const file = budgetsForecasts.find(i => !i.aprobado);
      if (file) {
        setIdMaster(file.id);
      }
    }
    setData(data);
  }, [loadingBudgets, budgetsForecasts]);

  // Approve confirm dialog
  const [approveFileConfirmDialogOpen, setApproveFileConfirmDialogOpen] = useState(false);

  const handleApproveFileConfirmDialogOpen = () => {
    setApproveFileConfirmDialogOpen(true);
  };

  const handleApproveFileConfirmDialogClose = () => {
    setApproveFileConfirmDialogOpen(false);
  };

  const approveFileConfirmDialog = () => (
    <ConfirmDialog
      show={approveFileConfirmDialogOpen}
      title="Aprobar Budget/Forecast"
      body="Para continuar, por favor confirme la acción."
      onConfirm={onAprobarArchivo}
      onClose={handleApproveFileConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - Approve confirm dialog

  // Approve OK dialog
  const [approveFileOKDialogOpen, setApproveFileOKDialogOpen] = useState(false);

  const handleApproveFileOKDialogOpen = () => {
    setApproveFileOKDialogOpen(true);
  };

  const handleApproveFileOKDialogClose = () => {
    setApproveFileOKDialogOpen(false);
  };

  const approveFileOKDialog = () => (
    <OKDialog
      show={approveFileOKDialogOpen}
      title="Aprobar Budget/Forecast"
      body="Archivo aprobado exitosamente."
      onConfirm={handleApproveFileOKDialogClose}
      onClose={handleApproveFileOKDialogClose}
    >
    </OKDialog>
  );
  // - Approve OK dialog

  return (
    <Grid container spacing={1}
      direction="column" justifyContent="flex-start"
      style={{ marginTop: '10px' }}
    >
      <Grid item xs={6} md={8} container direction="row" justifyContent="flex-start">
        <Grid item xs={12} md={5}>
          {loading ? (
            <Spinner size="small" />
          ) : (
            <FiltroFechaSelector
              id="anioFiscal"
              label="Año Fiscal"
              value={anioFiscalDate}
              minDate={anioFiscalDesde}
              maxDate={anioFiscalHasta}
              onDateChange={onAnioFiscalChange}
              format="YYYY"
              views={["year"]}
              disabled={loadingBudgets || loadingUploadBudgets || approveLoading}
              required={true}
              helperText={anioFiscalDate ? null : "Este campo es requerido"}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        {loadingBudgets && <Spinner size="small" />}
        {(!loadingBudgets && data.length > 0) && (
          <Table
            style={{ width: '100%', marginTop: 0, marginBottom: '15px' }}
            title=""
            noBoxShadow
            columns={columns}
            data={data}
            customOptions={{
              thirdSortClick: false,
              sorting: false,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 250
            }}
            tableKey={"tabla-selector-anio-fiscal"}
          />
        )}
      </Grid>
      {approveFileConfirmDialog()}
      {approveFileOKDialog()}
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.tenacta.aniosFiscales.loading,
    aniosFiscales: state.tenacta.aniosFiscales.items,
    estaCargado: state.tenacta.aniosFiscales.estaCargado,
    loadingBudgets: state.tenacta.budgetsForecastsInformeTres.loading,
    budgetsForecasts: state.tenacta.budgetsForecastsInformeTres.items,
    loadingUploadBudgets: state.tenacta.importadorBudgetForecastInformeTres.loading,
    approveLoading: state.tenacta.aprobarBudgetForecastInformeTres.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadAniosFiscales: () => dispatch(actions.loadAniosFiscales()),
    onLoadBudgets: (anioFiscal, onFail) => dispatch(actions.loadInformeTresBudgetsForecasts(anioFiscal, onFail)),
    onApproveFile: (idArchivo, onSuccess, onFail) =>
      dispatch(actions.approveInformeTresBudgetForecast(idArchivo, onSuccess, onFail)),
    onDownloadFile: (folder, fileName, containerName) =>
      dispatch(actions.downloadFile(folder, fileName, containerName))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorAnioFiscalTres);