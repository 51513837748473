const validateIsEmpty = (value) => {
  return (!value || value === "");
};

const validateIsNotANumber = (value) => {
  return isNaN(value);
};

const validateChannel = (id, data, cols) => {
  var hasError = false;
  const errors = [];
  cols.forEach(col => {
    if (!data.hasOwnProperty(col) || data[col] !== id) {
      hasError = true;
      errors.push(`La columna ${col} debe contener el código de canal ${id}.`);
    }
  });
  return { hasError, errors };
}

const validateChannels = (data, channels) => {
  const errors = [];
  channels.forEach(ch => {
    const { hasError, errors: channelErrors } = validateChannel(ch.channelId, data, ch.cols);
    if (hasError) errors.push(...channelErrors);
  });
  return { hasError: errors.length > 0, errors };
}

const __FILE_NAME__ = "% de Distribución";

export const validate = (json, anioFiscal = "") => {
  var result = [];

  const header1 = json[0];
  const header2 = json[1];

  // Header 1
  if (!header1.hasOwnProperty('A') || header1.A.toString().toLowerCase().trim() !== anioFiscal) {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: [`La celda A1 debe contener el año fiscal seleccionado (${anioFiscal}).`]
    });
  }
  
  const { hasError, errors } = validateChannels(header1, [
    { channelId: "007", cols: ["B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"] },
    { channelId: "010", cols: ["N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y"] },
    { channelId: "004", cols: ["Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK"] },
    { channelId: "012", cols: ["AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW"] },
    { channelId: "016", cols: ["AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI"] },
    { channelId: "001", cols: ["BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU"] },
    { channelId: "003", cols: ["BV", "BW", "BX", "BY", "BZ", "CA", "CB", "CC", "CD", "CE", "CF", "CG"] },
    { channelId: "002", cols: ["CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS"] },
    { channelId: "005", cols: ["CT", "CU", "CV", "CW", "CX", "CY", "CZ", "DA", "DB", "DC", "DD", "DE"] },
    { channelId: "013", cols: ["DF", "DG", "DH", "DI", "DJ", "DK", "DL", "DM", "DN", "DO", "DP", "DQ"] },
    { channelId: "018", cols: ["DR", "DS", "DT", "DU", "DV", "DW", "DX", "DY", "DZ", "EA", "EB", "EC"] },
  ]);

  debugger;
  if (header1.hasOwnProperty('ED') && header1.ED && header1.ED.toString().trim() !== '') {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: [`Los datos ingresados luego de la celda 'ED' no serán importados, ya que se excedió del límite de canales definidos.`]
    });
  }

  if (hasError) {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: errors
    });
  }

  // Header 2
  if (!header2.hasOwnProperty('A') || header2.A.toString().toLowerCase().trim() !== "codigo") {
    result.push({
      rowNumber: 2,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: ["La celda A2 debe decir CODIGO."]
    });
  }

  if (result && result.length) {
    return result;
  }

  json.slice(2).forEach(row => {
    var errors = [];

    // Column "A"
    if (!row.hasOwnProperty('A') || validateIsEmpty(row.A)) {
      errors.push("La columna A no puede estar vacía.");
    }

    // Columna "B" a "EC"
    ["B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    , "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ"
    , "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"
    , "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ"
    , "DA", "DB", "DC", "DD", "DE", "DF", "DG", "DH", "DI", "DJ", "DK", "DL", "DM", "DN", "DO", "DP", "DQ", "DR", "DS", "DT", "DU", "DV", "DW", "DX", "DY", "DZ"
    , "EA", "EB", "EC"].forEach(col => {
      if (!row.hasOwnProperty(col) || validateIsNotANumber(row[col])) {
        errors.push(`La columna ${col} debe contener números.`);
      }
    });

    // Fila duplicada
    const rowsDuplicated = json.filter(e => (
      e.hasOwnProperty('A') && row.hasOwnProperty('A')
      && e.A.toString().trim() === row.A.toString().trim()
      && e.__rowNum__ !== row.__rowNum__
    ));

    if (rowsDuplicated.length > 0) {
      const message = `La fila ${row.__rowNum__} se repite en `
        + `${rowsDuplicated.length === 1 ? 'la fila ' : 'las filas '}`
        + `${rowsDuplicated.map(e => e.__rowNum__).join(', ')}`;
      errors.push(message);
    }

    if (errors.length > 0) {
      result.push({
        rowNumber: row.__rowNum__,
        fileName: __FILE_NAME__,
        product: row.A,
        errors
      });
    }
  });

  return result;
}