import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  pivotGridConfiguraciones: {
    items: []
  },
  pivotGridInformaciones: {
    items: []
  },
  pivotGridConfiguracionFields: {
    items: []
  },
  informeUnoData: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: null,
    config: null,
    aprobado: true,
    esAnterior: false,
    anioInforme: null,
    filtros: {},
  },
  informeUnoFiltros: {
    anioFiscal: null,
    idArchivo: null,
    mesFiscal: null,
    acumulado: false,
    desde: null,
    hasta: null,
    anioComparacion: null,
  },
  aniosFiscales: {
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  budgetsForecasts: {
    anioFiscal: null,
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  importadorBudgetForecast: {
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  exportadorBudgetForecast: {
    loading: false,
    estaCargado: false,
    error: null,
  },
  importadorBudgetForecastInformeDos: {
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  aprobarBudgetForecast: {
    loading: false,
    estaCargado: false,
    error: null
  },
  aprobarBudgetForecastInformeDos: {
    loading: false,
    estaCargado: false,
    error: null
  },
  filtrosChartInformeUno: {
    optionSelectedId: 'marca',
    fieldSelectedId: 'unidades',
    orderSelectedId: 'totalUnidades',
    extraFilters: undefined,
    relatedFilters: undefined,
  },
  selectedTotalRows: {
    items: []
  },
  budgetsForecastsInformeDos: {
    anioFiscal: null,
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  informeDosData: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: {
      aspen: [],
      ortopedia: [],
      imetec: []
    },
    aprobado: true,
    esAnterior: false,
    anioInforme: null,
    filtros: {},
  },
  informeDosFiltros: {
    anioFiscal: null,
    idArchivo: null,
    mesFiscal: null,
    acumulado: false,
    desde: null,
    hasta: null,
  },
  informeDosExportarExpandido: {
    loading: false,
    estaCargado: false,
    error: null,
    excelBase64: ""
  },

  budgetsForecastsInformeTres: {
    anioFiscal: null,
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  informeTresData: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: [
      { linea: 'ASPEN', items: [] },
      { linea: 'ORTOPEDIA', items: [] },
      { linea: 'IMETEC', items: [] },
      { linea: 'TENACTA', items: [] }
    ],
    aprobado: true,
    esAnterior: false,
    anioInforme: null,
    filtros: {},
  },
  informeTresFiltros: {
    anioFiscal: null,
    idArchivo: null,
    mesFiscal: null,
    acumulado: false,
    desde: null,
    hasta: null,
    unidadDeNegocio: null,
    canal: null,
    vendedor: null
  },
  importadorBudgetForecastInformeTres: {
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  aprobarBudgetForecastInformeTres: {
    loading: false,
    estaCargado: false,
    error: null
  },
  unidadesDeNegocio: {
    loading: false,
    estaCargado: false,
    error: null,
    items: []
  },
  budgetForecastFile: {
    loading: false,
    estaCargado: false,
    error: null,
  }
};

//#region Pivot Grid Configuraciones
const setItemsConfiguraciones = (state, key, configuraciones, loading, estaCargado, error) => {
  let items = state.pivotGridConfiguraciones.items;
  items = items.filter((item) => item.key !== key);
  let selected = configuraciones && configuraciones.length !== 0 ? configuraciones[0] : undefined;

  let newItem = {
    key,
    configuraciones,
    selected,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

const loadPivotGridConfiguracionesStart = (state, key) => {
  let items = setItemsConfiguraciones(state, key, [], true, false, null);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items,
    })
  });
};

const loadPivotGridConfiguracionesSuccess = (state, key, configuraciones) => {
  let items = setItemsConfiguraciones(state, key, configuraciones, false, true, null);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items,
    })
  });
};

const loadPivotGridConfiguracionesFail = (state, key, error) => {
  let items = setItemsConfiguraciones(state, key, [], false, true, error);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items,
    })
  });
};

const clearPivotGridConfiguraciones = (state, key) => {
  let items = state.pivotGridConfiguraciones.items
    .filter((item) => item.key !== key);

  return updateObject(state, {
    pivotGridConfiguraciones: updateObject(state.pivotGridConfiguraciones, {
      items: items,
    })
  });
};

const updatePivotGridConfiguracionFields = (state, key, config) => {
  let items = state.pivotGridConfiguracionFields.items
    .filter((item) => item.key !== key);

  let item = {
    key,
    config
  };

  items.push(item);

  return updateObject(state, {
    pivotGridConfiguracionFields: updateObject(state.pivotGridConfiguracionFields, {
      items: items,
    })
  });
}

const updateSelectedTotalRows = (state, key, rows) => {
  let items = state.selectedTotalRows.items
    .filter((item) => item.key !== key);

  let item = {
    key,
    rows
  };

  items.push(item);

  return updateObject(state, {
    selectedTotalRows: updateObject(state.selectedTotalRows, {
      items: items,
    })
  });
}

//#endregion

//#region Pivot Grid Informaciones
const setItemsInformaciones = (state, key, informaciones, loading, estaCargado, error) => {
  let items = state.pivotGridInformaciones.items;
  let selected = informaciones && informaciones.length !== 0 ? informaciones[0] : undefined;
  items = items.filter((item) => item.key !== key);

  let newItem = {
    key,
    informaciones,
    selected,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

const loadPivotGridInformacionesStart = (state, key) => {
  let items = setItemsInformaciones(state, key, [], true, false, null);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

const loadPivotGridInformacionesSuccess = (state, key, informaciones) => {
  let items = setItemsInformaciones(state, key, informaciones, false, true, null);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

const loadPivotGridInformacionesFail = (state, key, error) => {
  let items = setItemsInformaciones(state, key, [], false, true, error);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

const clearPivotGridInformaciones = (state, key) => {
  let items = state.pivotGridInformaciones.items
    .filter((item) => item.key !== key);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

//#region Load Pivot Grid Informacion
const setItemsInformacion = (state, key, informacion, loading, estaCargado, error) => {
  let items = state.pivotGridInformaciones.items;
  let item = items.find((item) => item.key === key);

  if (item) {
    items = items.filter((item) => item.key !== key);
    item.selected = informacion;
    item.loading = loading;
    item.estaCargado = estaCargado;
    item.error = error;

    items.push(item);
  }

  return items;
}

const loadPivotGridInformacionStart = (state, key) => {
  let items = setItemsInformacion(state, key, {}, true, false, null);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

const loadPivotGridInformacionSuccess = (state, key, informacion) => {
  let items = setItemsInformacion(state, key, informacion, false, true, null);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

const loadPivotGridInformacionFail = (state, key, error) => {
  let items = setItemsInformacion(state, key, {}, false, true, error);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};

const clearPivotGridInformacion = (state, key) => {
  let items = state.pivotGridInformaciones.items
    .filter((item) => item.key !== key);

  return updateObject(state, {
    pivotGridInformaciones: updateObject(state.pivotGridInformaciones, {
      items: items,
    })
  });
};
//#endregion

//#endregion

//#region Load Informe Uno Data 
const loadInformeUnoDataStart = (state) => {
  return updateObject(state, {
    informeUnoData: updateObject(state.informeUnoData, {
      loading: true,
      error: null,
      datos: null,
      config: null,
      estaCargado: false,
      aprobado: true,
      esAnterior: false,
      anioInforme: null,
      filtros: {},
    })
  });
};

const loadInformeUnoDataSuccess = (state, datos, config, aprobado, esAnterior, filtros) => {
  return updateObject(state, {
    informeUnoData: updateObject(state.informeUnoData, {
      loading: false,
      error: null,
      datos,
      config,
      estaCargado: true,
      aprobado,
      esAnterior,
      anioInforme: filtros ? filtros.anioFiscal : null,
      filtros,
    })
  });
};

const loadInformeUnoDataFail = (state, error) => {
  return updateObject(state, {
    informeUnoData: updateObject(state.informeUnoData, {
      loading: false,
      error: error,
      estaCargado: true,
    })
  });
};

const clearInformeUnoData = (state) => {
  return updateObject(state, {
    informeUnoData: updateObject(state.informeUnoData, {
      loading: false,
      error: null,
      datos: null,
      config: null,
      estaCargado: false,
      aprobado: true,
      esAnterior: false,
      anioInforme: null,
      filtros: {},
    })
  });
};

const clearInformeUnoFiltros = (state) => {
  return updateObject(state, {
    informeUnoFiltros: updateObject(state.informeUnoFiltros, {
      anioFiscal: null,
      idArchivo: null,
      mesFiscal: null,
      acumulado: false,
      desde: null,
      hasta: null,
      anioComparacion: null,
    })
  });
}

const updateInformeUnoFiltros = (state, anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta, anioComparacion) => {
  return updateObject(state, {
    informeUnoFiltros: updateObject(state.informeUnoFiltros, {
      anioFiscal,
      idArchivo,
      mesFiscal,
      acumulado,
      desde,
      hasta,
      anioComparacion
    })
  });
}

const updateFiltrosChartInformeUno = (state, filtros) => {
  return updateObject(state, {
    filtrosChartInformeUno: updateObject(state.filtrosChartInformeUno, filtros)
  });
}
//#endregion

//#region Load Anios Fiscales
const loadAniosFiscalesStart = (state) => {
  return updateObject(state, {
    aniosFiscales: updateObject(state.aniosFiscales, {
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadAniosFiscalesSuccess = (state, anios) => {
  return updateObject(state, {
    aniosFiscales: updateObject(state.aniosFiscales, {
      loading: false,
      error: null,
      items: anios,
      estaCargado: true
    })
  });
};

const loadAniosFiscalesFail = (state, error) => {
  return updateObject(state, {
    aniosFiscales: updateObject(state.aniosFiscales, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearAniosFiscales = (state) => {
  return updateObject(state, {
    aniosFiscales: updateObject(state.aniosFiscales, {
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};
//#endregion

//#region Load Budgets Forecasts
const loadBudgetsForecastsStart = (state) => {
  return updateObject(state, {
    budgetsForecasts: updateObject(state.budgetsForecasts, {
      anioFiscal: null,
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadBudgetsForecastsSuccess = (state, anioFiscal, budgets) => {
  return updateObject(state, {
    budgetsForecasts: updateObject(state.budgetsForecasts, {
      anioFiscal,
      loading: false,
      error: null,
      items: budgets,
      estaCargado: true
    })
  });
};

const loadBudgetsForecastsFail = (state, anioFiscal, error) => {
  return updateObject(state, {
    budgetsForecasts: updateObject(state.budgetsForecasts, {
      anioFiscal,
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearBudgetsForecasts = (state) => {
  return updateObject(state, {
    budgetsForecasts: updateObject(state.budgetsForecasts, {
      anioFiscal: null,
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadInformeDosBudgetsForecastsStart = (state) => {
  return updateObject(state, {
    budgetsForecastsInformeDos: updateObject(state.budgetsForecastsInformeDos, {
      anioFiscal: null,
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadInformeDosBudgetsForecastsSuccess = (state, anioFiscal, budgets) => {
  return updateObject(state, {
    budgetsForecastsInformeDos: updateObject(state.budgetsForecastsInformeDos, {
      anioFiscal,
      loading: false,
      error: null,
      items: budgets,
      estaCargado: true
    })
  });
};

const loadInformeDosBudgetsForecastsFail = (state, anioFiscal, error) => {
  return updateObject(state, {
    budgetsForecastsInformeDos: updateObject(state.budgetsForecastsInformeDos, {
      anioFiscal,
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearInformeDosBudgetsForecasts = (state) => {
  return updateObject(state, {
    budgetsForecastsInformeDos: updateObject(state.budgetsForecastsInformeDos, {
      anioFiscal: null,
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};
//#endregion

//#region Importador Budgets Forecasts
const uploadFileDataToServerStart = (state) => {
  return updateObject(state, {
    importadorBudgetForecast: updateObject(state.importadorBudgetForecast, {
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const uploadFileDataToServerSuccess = (state, data) => {
  return updateObject(state, {
    importadorBudgetForecast: updateObject(state.importadorBudgetForecast, {
      loading: false,
      estaCargado: true,
      error: null,
      items: data
    })
  });
};

const uploadFileDataToServerFail = (state, error) => {
  return updateObject(state, {
    importadorBudgetForecast: updateObject(state.importadorBudgetForecast, {
      loading: false,
      estaCargado: true,
      error: error,
      items: []
    })
  });
};

const clearUploadFileDataToServer = (state) => {
  return updateObject(state, {
    importadorBudgetForecast: updateObject(state.importadorBudgetForecast, {
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const uploadFileToStorageAccountStart = (state) => {
  return updateObject(state, {
    exportadorBudgetForecast: updateObject(state.exportadorBudgetForecast, {
      loading: true,
      estaCargado: false,
      error: null,
    })
  });
};

const uploadFileToStorageAccountSuccess = (state) => {
  return updateObject(state, {
    exportadorBudgetForecast: updateObject(state.exportadorBudgetForecast, {
      loading: false,
      estaCargado: true,
      error: null,
    })
  });
};

const uploadFileToStorageAccountFail = (state, error) => {
  return updateObject(state, {
    exportadorBudgetForecast: updateObject(state.exportadorBudgetForecast, {
      loading: false,
      estaCargado: true,
      error: error,
    })
  });
};

const clearUploadFileToStorageAccount = (state) => {
  return updateObject(state, {
    exportadorBudgetForecast: updateObject(state.exportadorBudgetForecast, {
      loading: false,
      estaCargado: false,
      error: null,
    })
  });
};
//#endregion

//#region Importador Budgets Forecasts - Informe 2
const uploadInformeDosFileDataToServerStart = (state) => {
  return updateObject(state, {
    importadorBudgetForecastInformeDos: updateObject(state.importadorBudgetForecastInformeDos, {
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const uploadInformeDosFileDataToServerSuccess = (state, data) => {
  return updateObject(state, {
    importadorBudgetForecastInformeDos: updateObject(state.importadorBudgetForecastInformeDos, {
      loading: false,
      estaCargado: true,
      error: null,
      items: data
    })
  });
};

const uploadInformeDosFileDataToServerFail = (state, error) => {
  return updateObject(state, {
    importadorBudgetForecastInformeDos: updateObject(state.importadorBudgetForecastInformeDos, {
      loading: false,
      estaCargado: true,
      error: error,
      items: []
    })
  });
};

const clearUploadInformeDosFileDataToServer = (state) => {
  return updateObject(state, {
    importadorBudgetForecastInformeDos: updateObject(state.importadorBudgetForecastInformeDos, {
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};
//#endregion

//#region Importador Budgets Forecasts
const approveBudgetForecastStart = (state) => {
  return updateObject(state, {
    aprobarBudgetForecast: updateObject(state.aprobarBudgetForecast, {
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const approveBudgetForecastSuccess = (state) => {
  return updateObject(state, {
    aprobarBudgetForecast: updateObject(state.aprobarBudgetForecast, {
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const approveBudgetForecastFail = (state, error) => {
  return updateObject(state, {
    aprobarBudgetForecast: updateObject(state.aprobarBudgetForecast, {
      loading: false,
      estaCargado: true,
      error: error
    })
  });
};

const clearApproveBudgetForecast = (state) => {
  return updateObject(state, {
    aprobarBudgetForecast: updateObject(state.aprobarBudgetForecast, {
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region Importador Aprobar Budgets Forecasts - Informe 2
const approveInformeDosBudgetForecastStart = (state) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeDos: updateObject(state.aprobarBudgetForecastInformeDos, {
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const approveInformeDosBudgetForecastSuccess = (state) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeDos: updateObject(state.aprobarBudgetForecastInformeDos, {
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const approveInformeDosBudgetForecastFail = (state, error) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeDos: updateObject(state.aprobarBudgetForecastInformeDos, {
      loading: false,
      estaCargado: true,
      error: error
    })
  });
};

const clearApproveInformeDosBudgetForecast = (state) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeDos: updateObject(state.aprobarBudgetForecastInformeDos, {
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#region Informe Dos
const loadInformeDosDataStart = (state) => {
  return updateObject(state, {
    informeDosData: updateObject(state.informeDosData, {
      loading: true,
      error: null,
      datos: {
        aspen: [],
        ortopedia: [],
        imetec: []
      },
      estaCargado: false,
      aprobado: true,
      esAnterior: false,
      anioInforme: null,
      filtros: {},
    })
  });
};

const loadInformeDosDataSuccess = (state, datos, config, aprobado, esAnterior, filtros) => {
  return updateObject(state, {
    informeDosData: updateObject(state.informeDosData, {
      loading: false,
      error: null,
      datos,
      config,
      estaCargado: true,
      aprobado,
      esAnterior,
      anioInforme: filtros ? filtros.anioFiscal : null,
      filtros,
    })
  });
};

const loadInformeDosDataFail = (state, error) => {
  return updateObject(state, {
    informeDosData: updateObject(state.informeDosData, {
      loading: false,
      error: error,
      estaCargado: true,
    })
  });
};

const clearInformeDosData = (state) => {
  return updateObject(state, {
    informeDosData: updateObject(state.informeDosData, {
      loading: false,
      error: null,
      datos: {
        aspen: [],
        ortopedia: [],
        imetec: []
      },
      estaCargado: false,
      aprobado: true,
      esAnterior: false,
      anioInforme: null,
      filtros: {},
    })
  });
};

const clearInformeDosFiltros = (state) => {
  return updateObject(state, {
    informeDosFiltros: updateObject(state.informeDosFiltros, {
      anioFiscal: null,
      idArchivo: null,
      mesFiscal: null,
      acumulado: false,
      desde: null,
      hasta: null
    })
  });
}

const updateInformeDosFiltros = (state, anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta) => {
  return updateObject(state, {
    informeDosFiltros: updateObject(state.informeDosFiltros, {
      anioFiscal,
      idArchivo,
      mesFiscal,
      acumulado,
      desde,
      hasta
    })
  });
}

//#endregion

//#region Informe Dos - Exportar Expandido
const exportarInformeDosExpandidoStart = (state) => {
  return updateObject(state, {
    informeDosExportarExpandido: updateObject(state.informeDosExportarExpandido, {
      loading: true,
      error: null,
      excelBase64: "",
      estaCargado: false,
    })
  });
};

const exportarInformeDosExpandidoSuccess = (state, excelBase64) => {
  return updateObject(state, {
    informeDosExportarExpandido: updateObject(state.informeDosExportarExpandido, {
      loading: false,
      error: null,
      excelBase64,
      estaCargado: true,
    })
  });
};

const exportarInformeDosExpandidoFail = (state, error) => {
  return updateObject(state, {
    informeDosExportarExpandido: updateObject(state.informeDosExportarExpandido, {
      loading: false,
      error: error,
      estaCargado: true,
    })
  });
};

const exportarInformeDosExpandidoClear = (state, error) => {
  return updateObject(state, {
    informeDosExportarExpandido: updateObject(state.informeDosExportarExpandido, {
      loading: false,
      error: null,
      estaCargado: false,
      excelBase64: "",
    })
  });
};
//#endregion

//#region Informe Tres

//#region Informe Tres - Budget/Forecast

const loadInformeTresBudgetsForecastsStart = (state) => {
  return updateObject(state, {
    budgetsForecastsInformeTres: updateObject(state.budgetsForecastsInformeTres, {
      anioFiscal: null,
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadInformeTresBudgetsForecastsSuccess = (state, anioFiscal, budgets) => {
  return updateObject(state, {
    budgetsForecastsInformeTres: updateObject(state.budgetsForecastsInformeTres, {
      anioFiscal,
      loading: false,
      error: null,
      items: budgets,
      estaCargado: true
    })
  });
};

const loadInformeTresBudgetsForecastsFail = (state, anioFiscal, error) => {
  return updateObject(state, {
    budgetsForecastsInformeTres: updateObject(state.budgetsForecastsInformeTres, {
      anioFiscal,
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

const clearInformeTresBudgetsForecasts = (state) => {
  return updateObject(state, {
    budgetsForecastsInformeTres: updateObject(state.budgetsForecastsInformeTres, {
      anioFiscal: null,
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadUnidadesDeNegocioStart = (state) => {
  return updateObject(state, {
    unidadesDeNegocio: updateObject(state.unidadesDeNegocio, {
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const loadUnidadesDeNegocioSuccess = (state, unidadesDeNegocio) => {
  return updateObject(state, {
    unidadesDeNegocio: updateObject(state.unidadesDeNegocio, {
      loading: false,
      error: null,
      items: unidadesDeNegocio,
      estaCargado: true
    })
  });
};

const loadUnidadesDeNegocioFail = (state, error) => {
  return updateObject(state, {
    unidadesDeNegocio: updateObject(state.unidadesDeNegocio, {
      loading: false,
      error: error,
      estaCargado: true
    })
  });
};

//#endregion

//#region Informe Tres - Load Data

const loadInformeTresDataStart = (state) => {
  return updateObject(state, {
    informeTresData: updateObject(state.informeTresData, {
      loading: true,
      error: null,
      datos: [
        { linea: 'ASPEN', items: [] },
        { linea: 'ORTOPEDIA', items: [] },
        { linea: 'IMETEC', items: [] },
        { linea: 'TENACTA', items: [] }
      ],
      estaCargado: false,
      aprobado: true,
      esAnterior: false,
      anioInforme: null,
      filtros: {},
    })
  });
};

const loadInformeTresDataSuccess = (state, datos, config, aprobado, esAnterior, filtros) => {
  return updateObject(state, {
    informeTresData: updateObject(state.informeTresData, {
      loading: false,
      error: null,
      datos,
      config,
      estaCargado: true,
      aprobado,
      esAnterior,
      anioInforme: filtros ? filtros.anioFiscal : null,
      filtros,
    })
  });
};

const loadInformeTresDataFail = (state, error) => {
  return updateObject(state, {
    informeTresData: updateObject(state.informeTresData, {
      loading: false,
      error: error,
      estaCargado: true,
    })
  });
};

const clearInformeTresData = (state) => {
  return updateObject(state, {
    informeTresData: updateObject(state.informeTresData, {
      loading: false,
      error: null,
      datos: [
        { linea: 'aspen', items: [] },
        { linea: 'ortopedia', items: [] },
        { linea: 'imetec', items: [] },
        { linea: 'total', items: [] }
      ],
      estaCargado: false,
      aprobado: true,
      esAnterior: false,
      anioInforme: null,
      filtros: {},
    })
  });
};

const clearInformeTresFiltros = (state) => {
  return updateObject(state, {
    informeTresFiltros: updateObject(state.informeTresFiltros, {
      anioFiscal: null,
      idArchivo: null,
      mesFiscal: null,
      acumulado: false,
      desde: null,
      hasta: null,
      unidadDeNegocio: null,
      canal: null,
      vendedor: null
    })
  });
}

const updateInformeTresFiltros = (state, anioFiscal, idArchivo, mesFiscal, acumulado, desde, hasta, unidadDeNegocio, canal, vendedor) => {
  return updateObject(state, {
    informeTresFiltros: updateObject(state.informeTresFiltros, {
      anioFiscal,
      idArchivo,
      mesFiscal,
      acumulado,
      desde,
      hasta,
      unidadDeNegocio,
      canal,
      vendedor
    })
  });
}

//#endregion

//#region Informe Tres - Importador Budgets Forecasts
const uploadInformeTresFileDataToServerStart = (state) => {
  return updateObject(state, {
    importadorBudgetForecastInformeTres: updateObject(state.importadorBudgetForecastInformeTres, {
      loading: true,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};

const uploadInformeTresFileDataToServerSuccess = (state, data) => {
  return updateObject(state, {
    importadorBudgetForecastInformeTres: updateObject(state.importadorBudgetForecastInformeTres, {
      loading: false,
      estaCargado: true,
      error: null,
      items: data
    })
  });
};

const uploadInformeTresFileDataToServerFail = (state, error) => {
  return updateObject(state, {
    importadorBudgetForecastInformeTres: updateObject(state.importadorBudgetForecastInformeTres, {
      loading: false,
      estaCargado: true,
      error: error,
      items: []
    })
  });
};

const clearUploadInformeTresFileDataToServer = (state) => {
  return updateObject(state, {
    importadorBudgetForecastInformeTres: updateObject(state.importadorBudgetForecastInformeTres, {
      loading: false,
      estaCargado: false,
      error: null,
      items: []
    })
  });
};
//#endregion

//#region Informe Tres - Importador Aprobar Budgets Forecasts
const approveInformeTresBudgetForecastStart = (state) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeTres: updateObject(state.aprobarBudgetForecastInformeTres, {
      loading: true,
      estaCargado: false,
      error: null
    })
  });
};

const approveInformeTresBudgetForecastSuccess = (state) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeTres: updateObject(state.aprobarBudgetForecastInformeTres, {
      loading: false,
      estaCargado: true,
      error: null
    })
  });
};

const approveInformeTresBudgetForecastFail = (state, error) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeTres: updateObject(state.aprobarBudgetForecastInformeTres, {
      loading: false,
      estaCargado: true,
      error: error
    })
  });
};

const clearApproveInformeTresBudgetForecast = (state) => {
  return updateObject(state, {
    aprobarBudgetForecastInformeTres: updateObject(state.aprobarBudgetForecastInformeTres, {
      loading: false,
      estaCargado: false,
      error: null
    })
  });
};
//#endregion

//#endregion

const downloadFileStart = (state) => {
  return updateObject(state, {
    budgetForecastFile: updateObject(state.budgetForecastFile, {
      loading: true,
      estaCargado: false,
      error: null,
      file: []
    })
  });
}

const downloadFileSuccess = (state, data) => {
  return updateObject(state, {
    budgetForecastFile: updateObject(state.budgetForecastFile, {
      loading: false,
      estaCargado: true,
      error: null,
      file: data
    })
  });
}

const downloadFileFail = (state, error) => {
  return updateObject(state, {
    budgetForecastFile: updateObject(state.budgetForecastFile, {
      loading: false,
      estaCargado: true,
      error: error,
      file: []
    })
  });
}

const clearDownloadFile = (state) => {
  return updateObject(state, {
    budgetForecastFile: updateObject(state.budgetForecastFile, {
      loading: false,
      estaCargado: false,
      error: null,
      file: []
    })
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_START:
      return loadPivotGridConfiguracionesStart(state, action.key);
    case actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_SUCCESS:
      return loadPivotGridConfiguracionesSuccess(state, action.key, action.configuraciones);
    case actionTypes.LOAD_PIVOT_GRID_CONFIGURACIONES_FAIL:
      return loadPivotGridConfiguracionesFail(state, action.key, action.error);
    case actionTypes.CLEAR_PIVOT_GRID_CONFIGURACIONES:
      return clearPivotGridConfiguraciones(state, action.key);

    case actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_START:
      return loadPivotGridInformacionesStart(state, action.key);
    case actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_SUCCESS:
      return loadPivotGridInformacionesSuccess(state, action.key, action.informaciones);
    case actionTypes.LOAD_PIVOT_GRID_INFORMACIONES_FAIL:
      return loadPivotGridInformacionesFail(state, action.key, action.error);
    case actionTypes.CLEAR_PIVOT_GRID_INFORMACIONES:
      return clearPivotGridInformaciones(state, action.key);

    case actionTypes.LOAD_PIVOT_GRID_INFORMACION_START:
      return loadPivotGridInformacionStart(state, action.key);
    case actionTypes.LOAD_PIVOT_GRID_INFORMACION_SUCCESS:
      return loadPivotGridInformacionSuccess(state, action.key, action.informacion);
    case actionTypes.LOAD_PIVOT_GRID_INFORMACION_FAIL:
      return loadPivotGridInformacionFail(state, action.key, action.error);
    case actionTypes.CLEAR_PIVOT_GRID_INFORMACION:
      return clearPivotGridInformacion(state, action.key);
    case actionTypes.UPDATE_PIVOT_GRID_CONFIGURACION_FIELDS:
      return updatePivotGridConfiguracionFields(state, action.key, action.config);
    case actionTypes.UPDATE_SELECTED_TOTAL_ROWS:
      return updateSelectedTotalRows(state, action.key, action.rows);

    //#region INFORME UNO DATA
    case actionTypes.LOAD_INFORME_UNO_DATA_START:
      return clearInformeUnoFiltros(state) && loadInformeUnoDataStart(state);
    case actionTypes.LOAD_INFORME_UNO_DATA_SUCCESS:
      return loadInformeUnoDataSuccess(state, action.data, action.config, action.aprobado, action.esAnterior, action.filtros);
    case actionTypes.LOAD_INFORME_UNO_DATA_FAIL:
      return loadInformeUnoDataFail(state, action.error);
    case actionTypes.CLEAR_INFORME_UNO_DATA:
      return clearInformeUnoFiltros(state) && clearInformeUnoData(state);
    case actionTypes.UPDATE_INFORME_UNO_FILTROS:
      return updateInformeUnoFiltros(state, action.anioFiscal, action.idArchivo, action.mesFiscal,
        action.acumulado, action.desde, action.hasta, action.anioComparacion);
    case actionTypes.UPDATE_FILTROS_CHART_INFORME_UNO:
      return updateFiltrosChartInformeUno(state, action.filtros);
    //#endregion

    //#region INFORME DOS DATA
    case actionTypes.LOAD_INFORME_DOS_DATA_START:
      return clearInformeDosFiltros(state) && loadInformeDosDataStart(state);
    case actionTypes.LOAD_INFORME_DOS_DATA_SUCCESS:
      return loadInformeDosDataSuccess(state, action.data, action.config, action.aprobado, action.esAnterior, action.filtros);
    case actionTypes.LOAD_INFORME_DOS_DATA_FAIL:
      return loadInformeDosDataFail(state, action.error);
    case actionTypes.CLEAR_INFORME_DOS_DATA:
      return clearInformeDosFiltros(state) && clearInformeDosData(state);
    case actionTypes.UPDATE_INFORME_DOS_FILTROS:
      return updateInformeDosFiltros(state, action.anioFiscal, action.idArchivo, action.mesFiscal,
        action.acumulado, action.desde, action.hasta);

    //#endregion

    //#region INFORME DOS - EXPORTAR EXPANDIDO
    case actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_START:
      return exportarInformeDosExpandidoStart(state);
    case actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_SUCCESS:
      return exportarInformeDosExpandidoSuccess(state, action.excelBase64);
    case actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_FAIL:
      return exportarInformeDosExpandidoFail(state, action.error);
    case actionTypes.EXPORTAR_INFORME_DOS_EXPANDIDO_CLEAR:
      return exportarInformeDosExpandidoClear(state);

    //#endregion  

    //#region ANIOS FISCALES
    case actionTypes.LOAD_ANIOS_FISCALES_START:
      return loadAniosFiscalesStart(state);
    case actionTypes.LOAD_ANIOS_FISCALES_SUCCESS:
      return loadAniosFiscalesSuccess(state, action.anios);
    case actionTypes.LOAD_ANIOS_FISCALES_FAIL:
      return loadAniosFiscalesFail(state, action.error);
    case actionTypes.CLEAR_ANIOS_FISCALES:
      return clearAniosFiscales(state);
    //#endregion

    //#region BUDGETS FORECASTS
    case actionTypes.LOAD_BUDGETS_FORECASTS_START:
      return loadBudgetsForecastsStart(state);
    case actionTypes.LOAD_BUDGETS_FORECASTS_SUCCESS:
      return loadBudgetsForecastsSuccess(state, action.anio, action.budgets);
    case actionTypes.LOAD_BUDGETS_FORECASTS_FAIL:
      return loadBudgetsForecastsFail(state, action.anio, action.error);
    case actionTypes.CLEAR_BUDGETS_FORECASTS:
      return clearBudgetsForecasts(state);

    case actionTypes.LOAD_INFORME_DOS_BUDGETS_FORECASTS_START:
      return loadInformeDosBudgetsForecastsStart(state);
    case actionTypes.LOAD_INFORME_DOS_BUDGETS_FORECASTS_SUCCESS:
      return loadInformeDosBudgetsForecastsSuccess(state, action.anio, action.budgets);
    case actionTypes.LOAD_INFORME_DOS_BUDGETS_FORECASTS_FAIL:
      return loadInformeDosBudgetsForecastsFail(state, action.anio, action.error);
    case actionTypes.CLEAR_INFORME_DOS_BUDGETS_FORECASTS:
      return clearInformeDosBudgetsForecasts(state);
    //#endregion

    //#region IMPORTADOR BUDGETS FORECASTS
    case actionTypes.UPLOAD_FILE_DATA_TO_SERVER_START:
      return uploadFileDataToServerStart(state);
    case actionTypes.UPLOAD_FILE_DATA_TO_SERVER_SUCCESS:
      return uploadFileDataToServerSuccess(state, action.data);
    case actionTypes.UPLOAD_FILE_DATA_TO_SERVER_FAIL:
      return uploadFileDataToServerFail(state, action.error);
    case actionTypes.CLEAR_UPLOAD_FILE_DATA_TO_SERVER:
      return clearUploadFileDataToServer(state);
    //#endregion

    //#region IMPORTADOR BUDGETS FORECASTS - Informe 2
    case actionTypes.UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_START:
      return uploadInformeDosFileDataToServerStart(state);
    case actionTypes.UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_SUCCESS:
      return uploadInformeDosFileDataToServerSuccess(state, action.data);
    case actionTypes.UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER_FAIL:
      return uploadInformeDosFileDataToServerFail(state, action.error);
    case actionTypes.CLEAR_UPLOAD_INFORME_DOS_FILE_DATA_TO_SERVER:
      return clearUploadInformeDosFileDataToServer(state);

    case actionTypes.UPLOAD_FILE_TO_STORAGE_ACCOUNT:
      return uploadFileToStorageAccountStart(state);
    case actionTypes.UPLOAD_FILE_TO_STORAGE_ACCOUNT_SUCCESS:
      return uploadFileToStorageAccountSuccess(state, action.data);
    case actionTypes.UPLOAD_FILE_TO_STORAGE_ACCOUNT_FAIL:
      return uploadFileToStorageAccountFail(state, action.error);
    case actionTypes.CLEAR_UPLOAD_FILE_TO_STORAGE_ACCOUNT:
      return clearUploadFileToStorageAccount(state);

    //#endregion

    //#region APROBAR BUDGETS FORECASTS
    case actionTypes.APPROVE_BUDGET_FORECAST_START:
      return approveBudgetForecastStart(state);
    case actionTypes.APPROVE_BUDGET_FORECAST_SUCCESS:
      return approveBudgetForecastSuccess(state, action.data);
    case actionTypes.APPROVE_BUDGET_FORECAST_FAIL:
      return approveBudgetForecastFail(state, action.error);
    case actionTypes.CLEAR_APPROVE_BUDGET_FORECAST:
      return clearApproveBudgetForecast(state);
    //#endregion

    //#region APROBAR BUDGETS FORECASTS - Informe 2
    case actionTypes.APPROVE_INFORME_DOS_BUDGET_FORECAST_START:
      return approveInformeDosBudgetForecastStart(state);
    case actionTypes.APPROVE_INFORME_DOS_BUDGET_FORECAST_SUCCESS:
      return approveInformeDosBudgetForecastSuccess(state, action.data);
    case actionTypes.APPROVE_INFORME_DOS_BUDGET_FORECAST_FAIL:
      return approveInformeDosBudgetForecastFail(state, action.error);
    case actionTypes.CLEAR_APPROVE_INFORME_DOS_BUDGET_FORECAST:
      return clearApproveInformeDosBudgetForecast(state);
    //#endregion

    //#region INFORME TRES

    //#region INFORME TRES - BUDGET/FORECAST

    case actionTypes.LOAD_INFORME_TRES_BUDGETS_FORECASTS_START:
      return loadInformeTresBudgetsForecastsStart(state);
    case actionTypes.LOAD_INFORME_TRES_BUDGETS_FORECASTS_SUCCESS:
      return loadInformeTresBudgetsForecastsSuccess(state, action.anio, action.budgets);
    case actionTypes.LOAD_INFORME_TRES_BUDGETS_FORECASTS_FAIL:
      return loadInformeTresBudgetsForecastsFail(state, action.anio, action.error);
    case actionTypes.CLEAR_INFORME_TRES_BUDGETS_FORECASTS:
      return clearInformeTresBudgetsForecasts(state);

    case actionTypes.LOAD_INFORME_TRES_UNIDADES_NEGOCIO_START:
      return loadUnidadesDeNegocioStart(state);
    case actionTypes.LOAD_INFORME_TRES_UNIDADES_NEGOCIO_SUCCESS:
      return loadUnidadesDeNegocioSuccess(state, action.unidades);
    case actionTypes.LOAD_INFORME_TRES_UNIDADES_NEGOCIO_FAIL:
      return loadUnidadesDeNegocioFail(state, action.error);
    //#endregion

    //#region INFORME TRES - LOAD DATA
    case actionTypes.LOAD_INFORME_TRES_DATA_START:
      return clearInformeTresFiltros(state) && loadInformeTresDataStart(state);
    case actionTypes.LOAD_INFORME_TRES_DATA_SUCCESS:
      return loadInformeTresDataSuccess(state, action.data, action.config, action.aprobado, action.esAnterior, action.filtros);
    case actionTypes.LOAD_INFORME_TRES_DATA_FAIL:
      return loadInformeTresDataFail(state, action.error);
    case actionTypes.CLEAR_INFORME_TRES_DATA:
      return clearInformeTresFiltros(state) && clearInformeTresData(state);
    case actionTypes.UPDATE_INFORME_TRES_FILTROS:
      return updateInformeTresFiltros(state, action.anioFiscal, action.idArchivo, action.mesFiscal,
        action.acumulado, action.desde, action.hasta, action.unidadDeNegocio, action.canal, action.vendedor);
    //#endregion

    //#region  INFORME TRES - IMPORTADOR BUDGETS FORECASTS
    case actionTypes.UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_START:
      return uploadInformeTresFileDataToServerStart(state);
    case actionTypes.UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_SUCCESS:
      return uploadInformeTresFileDataToServerSuccess(state, action.data);
    case actionTypes.UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER_FAIL:
      return uploadInformeTresFileDataToServerFail(state, action.error);
    case actionTypes.CLEAR_UPLOAD_INFORME_TRES_FILE_DATA_TO_SERVER:
      return clearUploadInformeTresFileDataToServer(state);

    //#endregion

    //#region INFORME TRES - APROBAR BUDGETS FORECASTS
    case actionTypes.APPROVE_INFORME_TRES_BUDGET_FORECAST_START:
      return approveInformeTresBudgetForecastStart(state);
    case actionTypes.APPROVE_INFORME_TRES_BUDGET_FORECAST_SUCCESS:
      return approveInformeTresBudgetForecastSuccess(state, action.data);
    case actionTypes.APPROVE_INFORME_TRES_BUDGET_FORECAST_FAIL:
      return approveInformeTresBudgetForecastFail(state, action.error);
    case actionTypes.CLEAR_APPROVE_INFORME_TRES_BUDGET_FORECAST:
      return clearApproveInformeTresBudgetForecast(state);
    //#endregion

    //#endregion

    case actionTypes.DOWNLOAD_FILE_START:
      return downloadFileStart(state);
    case actionTypes.DOWNLOAD_FILE_SUCCESS:
      return downloadFileSuccess(state, action.data);
    case actionTypes.DOWNLOAD_FILE_FAIL:
      return downloadFileFail(state, action.error);
    case actionTypes.CLEAR_DOWNLOAD_FILE:
      return clearDownloadFile(state);

    default:
      return state;
  }
};

export default reducer;