import _ from "lodash"

const validateIsEmpty = (value) => {
  return (!value || value === "");
};

const validateIsNotANumber = (value) => {
  return isNaN(value);
};

const fillVendedores = (data, anioFiscal) => {
  var items = Object.keys(data).map(key => ({
    cell: key,
    value: data[key]
  }));

  const groups = _.groupBy(items,  'value');
  
  var headers = Object.keys(groups).map(key => ({
    vendedorId: key,
    cols: groups[key].map(item => item.cell)
  }));

  const result = headers.filter((header) => header.vendedorId !== anioFiscal);
  return result;
}

const validateVendedor = (id, data, cols) => {
  var hasError = false;
  const errors = [];
  cols.forEach(col => {
    if (!data.hasOwnProperty(col) || data[col] !== id) {
      hasError = true;
      errors.push(`La columna ${col} debe contener el código de vendedor ${id}.`);
    }
  });
  return { hasError, errors };
}

const validateVendedores = (data, vendedores) => {
  const errors = [];
  vendedores.forEach(ch => {
    const { hasError, errors: vendedorErrors } = validateVendedor(ch.vendedorId, data, ch.cols);
    if (hasError) errors.push(...vendedorErrors);
  });
  return { hasError: errors.length > 0, errors };
}

const __FILE_NAME__ = "% de Vendedores";

export const validate = (json, anioFiscal = "") => {
  var result = [];

  const header1 = json[0];
  const header2 = json[1];  

  // Header 1
  if (!header1.hasOwnProperty('A') || header1.A.toString().toLowerCase().trim() !== anioFiscal) {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: [`La celda A1 debe contener el año fiscal seleccionado (${anioFiscal}).`]
    });
  }

  const vendedores = fillVendedores(header1, anioFiscal);  
  const { hasError, errors } = validateVendedores(header1, vendedores);

  if (hasError) {
    result.push({
      rowNumber: 1,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: errors
    });
  }

  // Header 2
  if (!header2.hasOwnProperty('A') || header2.A.toString().toLowerCase().trim() !== "codigo") {
    result.push({
      rowNumber: 2,
      fileName: __FILE_NAME__,
      product: "Cabecera del archivo",
      errors: ["La celda A2 debe decir CODIGO."]
    });
  }

  if (result && result.length) {
    return result;
  }

  json.slice(2).forEach(row => {
    var errors = [];

    // Column "A"
    if (!row.hasOwnProperty('A') || validateIsEmpty(row.A)) {
      errors.push("La columna A no puede estar vacía.");
    }

    // Validar columnas en vendedores
    vendedores.forEach((vendedor) => {
      vendedor.cols.forEach(col => {
        if (!row.hasOwnProperty(col) || validateIsNotANumber(row[col])) {
          errors.push(`La columna ${col} debe contener números.`);
        }
      });
    });

    // Fila duplicada
    const rowsDuplicated = json.filter(e => (
      e.hasOwnProperty('A') && row.hasOwnProperty('A')
      && e.A.toString().trim() === row.A.toString().trim()
      && e.__rowNum__ !== row.__rowNum__
    ));

    if (rowsDuplicated.length > 0) {
      const message = `La fila ${row.__rowNum__} se repite en `
        + `${rowsDuplicated.length === 1 ? 'la fila ' : 'las filas '}`
        + `${rowsDuplicated.map(e => e.__rowNum__).join(', ')}`;
      errors.push(message);
    }

    if (errors.length > 0) {
      result.push({
        rowNumber: row.__rowNum__,
        fileName: __FILE_NAME__,
        product: row.A,
        errors
      });
    }
  });

  return result;
}